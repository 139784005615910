import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import type { NormalizedCacheObject } from "@apollo/client";
import React, { useMemo } from "react";
import { getIsomorphicAPIUrl } from "../../lib/getIsomorphicAPIUrl";

export const AdminApolloClientContext =
  React.createContext<ApolloClient<NormalizedCacheObject>>(null);

export const useAdminApolloClient = () =>
  React.useContext(AdminApolloClientContext);

const AdminApolloProvider: React.FC = ({ children }) => {
  const client = useMemo(() => {
    const httpLink = new HttpLink({
      uri: getIsomorphicAPIUrl("/admin"),
      credentials: "include",
    });
    return new ApolloClient({
      link: httpLink,
      cache: new InMemoryCache(),
    });
  }, []);
  return (
    <AdminApolloClientContext.Provider value={client}>
      {children}
    </AdminApolloClientContext.Provider>
  );
};

export default AdminApolloProvider;
