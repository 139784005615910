/* 📌 IMPORTS */
import { createTypedHooks } from "easy-peasy";
import cart from "../modules/cart/model";
import checkout from "../modules/checkout/model";
import form from "../modules/form/model";
import navigation from "../modules/navigation/model";
import order from "../modules/order/model";
import product from "../modules/product/model";
import profile from "../modules/profile/model";
import reviews from "../modules/reviews/model";
import type { AppModel } from "./types";

export const model = {
  cart,
  checkout,
  form,
  product,
  profile,
  navigation,
  reviews,
  order,
};

const { useStoreActions, useStoreState, useStoreDispatch } =
  createTypedHooks<AppModel>();
export { useStoreActions, useStoreState, useStoreDispatch };

export default model;
