import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import type { GetEmployeeInfo } from "./types/GetEmployeeInfo";

export const EmployeeFragment = gql`
  fragment EmployeeFragment on Employee {
    id
    firstName
    lastName
    jobTitle

    profileImage {
      thumbUrl(width: 200)
    }
  }
`;
const GET_EMPLOYEE_INFO = gql`
  query GetEmployeeInfo($id: ID!) {
    employee(id: $id) {
      ...EmployeeFragment
    }
  }
  ${EmployeeFragment}
`;

export const useEmployee = ({ id }: { id: string }) => {
  return useQuery<GetEmployeeInfo>(GET_EMPLOYEE_INFO, {
    variables: { id },
    skip: !id,
  });
};
