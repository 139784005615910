import dynamic from "next/dynamic";
import React from "react";
import { StickyContainer } from "react-sticky";
import styled from "styled-components";
import HeaderBenefits from "../../navigation/components/HeaderBenefits";
import ImpersonisationHeader from "../../profile/components/ImpersonisationHeader";

import RoutePaletteThemeProvider from "./RoutePaletteThemeProvider";

const Footer = dynamic(() => import("./Footer"));

const SearchOverlayMobile = dynamic({
  loader: () => import("../../search/components/SearchOverlayMobile"),
  ssr: false,
});

const Base = styled.div``;

const BasePageLayout: React.FC<{}> = ({ children }) => {
  return (
    <RoutePaletteThemeProvider>
      <Base>
        <StickyContainer>
          <HeaderBenefits />
          <ImpersonisationHeader />

          <SearchOverlayMobile />

          {children}

          <Footer />
        </StickyContainer>
      </Base>
    </RoutePaletteThemeProvider>
  );
};

export default BasePageLayout;
