import type { Action, Computed, Thunk } from "easy-peasy";
import { action, computed, thunk } from "easy-peasy";
import Router from "next/router";
import { SEARCH_PAGE_PATH } from "../../config/locations";
import { scrollUpToProductList } from "../categories/utils/scrollUpToProductList";
import { POPUP_FLASH_DURATION_MS } from "../../config/constants";
import { debounce } from "lodash-es";
import type { MinCanonicalNavItemProduct } from "../product/utils/makeProductNavItem";
import { makeProductCanonicalNavItem } from "../product/utils/makeProductNavItem";

interface State {
  mobileNavVisible: boolean;
  cartPopupVisible: boolean;
  cartPopupHovered: boolean;
  wishlistPopupVisible: boolean;
  searchTerm: string;
  benefitsNavCounter: number;
  scannerVisible: boolean;
  setScannerVisible: Action<State, boolean>;
  benefitsNavVisible: Computed<State, boolean>;
  showMobileNav: Action<State>;
  hideMobileNav: Action<State>;
  toggleMobileNav: Action<State>;
  showCartPopup: Action<State>;
  hideCartPopup: Action<State>;
  flashCartPopup: Thunk<State, void>;
  setCartPopupVisible: Action<State, boolean>;
  setCartPopupHovered: Action<State, boolean>;
  showWishlistPopup: Action<State>;
  hideWishlistPopup: Action<State>;
  setWishlistPopupVisible: Action<State, boolean>;
  closeBenefitsNav: Action<State>;
  increaseBenefitsNavCounter: Action<State>;
  setSearchTerm: Action<State, string>;
  gotoSearchResults: Thunk<State, void>;
  singleProductSearchResult?: MinCanonicalNavItemProduct; // see https://git.panter.ch/manul/vps/veloplus-shop/-/issues/936
  setSingleProductSearchResult: Action<State, MinCanonicalNavItemProduct>;
  searchOverlayVisible: boolean;
  setSearchOverLayVisible: Action<State, boolean>;
}

const logSearchTermToGTM = debounce((searchTerm) => {
  if (searchTerm) {
    window?.dataLayer?.push({
      event: "ga_search_autoComplete",
      searchTerm_ac: searchTerm,
    });
  }
}, 1500);

const model: State = {
  scannerVisible: false,
  searchOverlayVisible: false,
  mobileNavVisible: false,
  cartPopupVisible: false,
  cartPopupHovered: false,
  wishlistPopupVisible: false,
  searchTerm: "",
  benefitsNavCounter: 0,
  benefitsNavVisible: computed((state) => state.benefitsNavCounter < 5),
  showMobileNav: action((state) => {
    state.mobileNavVisible = true;
  }),
  hideMobileNav: action((state) => {
    state.mobileNavVisible = false;
  }),
  toggleMobileNav: action((state) => {
    state.mobileNavVisible = !state.mobileNavVisible;
  }),
  showCartPopup: action((state) => {
    state.cartPopupVisible = true;
  }),
  setScannerVisible: action((state, visible) => {
    state.scannerVisible = visible;
  }),
  hideCartPopup: action((state) => {
    state.cartPopupVisible = false;
    state.cartPopupHovered = false;
  }),
  flashCartPopup: thunk(async (actions, payload, { getState }) => {
    actions.showCartPopup();
    setTimeout(() => {
      if (!getState().cartPopupHovered) {
        actions.hideCartPopup();
      }
    }, POPUP_FLASH_DURATION_MS);
  }),
  setCartPopupVisible: action((state, visible) => {
    state.cartPopupVisible = visible;
    state.cartPopupHovered = visible ? state.cartPopupHovered : false;
  }),
  setCartPopupHovered: action((state, hovered) => {
    state.cartPopupHovered = hovered;
  }),
  showWishlistPopup: action((state) => {
    state.wishlistPopupVisible = true;
  }),
  hideWishlistPopup: action((state) => {
    state.wishlistPopupVisible = false;
  }),
  setWishlistPopupVisible: action((state, visible) => {
    state.wishlistPopupVisible = visible;
  }),
  setSearchTerm: action<State, string>((state, searchTerm = "") => {
    // see https://git.panter.ch/manul/vps/veloplus-shop/-/issues/906
    logSearchTermToGTM(searchTerm);
    state.searchTerm = searchTerm;
  }),
  setSearchOverLayVisible: action<State, boolean>((state, visible) => {
    state.searchOverlayVisible = visible;
  }),
  closeBenefitsNav: action((state) => {
    state.benefitsNavCounter = 5;
  }),
  increaseBenefitsNavCounter: action((state) => {
    state.benefitsNavCounter++;
  }),
  setSingleProductSearchResult: action((state, product) => {
    state.singleProductSearchResult = product;
  }),
  gotoSearchResults: thunk(async (actions, payload, { getState }) => {
    const singleProductSearchResult = getState().singleProductSearchResult;
    if (singleProductSearchResult) {
      const navItem = makeProductCanonicalNavItem(singleProductSearchResult);
      await Router.push(navItem.href, navItem.path);
    } else {
      await Router.push(
        `${SEARCH_PAGE_PATH}?q=${getState().searchTerm}`, // href
        `${SEARCH_PAGE_PATH}/${getState().searchTerm}`, // asPath
      );
      scrollUpToProductList();
    }
    actions.setSearchOverLayVisible(false);
  }),
};

export default model;
