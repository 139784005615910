import gql from "graphql-tag";
import { EmployeeFragment } from "../../content/hooks/useEmployee";

export const ProductMedia = gql`
  fragment ProductMedia on ShopProductMedia {
    _id
    file {
      _id
      name
      url: thumbUrl(width: 2048, height: 1536)
      thumbUrl(width: 450, height: 282)
      largeThumbUrl: thumbUrl(width: 735, height: 460)
    }
  }
`;

export const ProductTextsParts = gql`
  fragment ProductTextsParts on ShopProductTexts {
    _id
    slug
    title
    description
    brand
  }
`;

export const ProductVariationOption = gql`
  fragment ProductVariationOption on ShopProductVariationOption {
    _id
    value
    texts {
      _id
      title
      subtitle
    }
  }
`;

export const SimpleProductPrices = gql`
  fragment SimpleProductPrices on ShopSimpleProduct {
    vpsCatalogPrice {
      amount
      currency
    }
    vpsDiscountedCatalogPrice {
      amount
      currency
    }
  }
`;

export const ProductNaviItemPathParts = gql`
  fragment ProductNaviItemPathParts on ShopProductAssortmentPath {
    vpsLeafLink {
      assortmentId
      assortmentTexts {
        slug
      }
    }
  }
`;
export const BaseProductNavItemParts = gql`
  fragment BaseProductNavItemParts on ShopConfigurableProduct {
    _id
    vpsPrimaryAssortmentPath {
      ...ProductNaviItemPathParts
    }
  }
  ${ProductNaviItemPathParts}
`;

export const VariantProductNavItemParts = gql`
  fragment VariantProductNavItemParts on ShopSimpleProduct {
    _id
    vpsPrimaryAssortmentPath {
      ...ProductNaviItemPathParts
    }
  }
  ${ProductNaviItemPathParts}
`;

export const VpsColorVariant = gql`
  fragment VpsColorVariant on ShopVPSColorVariant {
    _id
    value
    hexValue
    vendorName
  }
`;

export const ProductVariantBaseProduct = gql`
  fragment ProductVariantBaseProduct on ShopSimpleProduct {
    _id

    vpsAvailability
    vpsEAN
    tags
    vpsVariantTitle
    vpsLegacyArticleNumber
    vpsIsLongTail
    ...SimpleProductPrices
    texts {
      _id
      slug
    }
  }
  ${SimpleProductPrices}
`;

export const VpsColorVariantWithProducts = gql`
  fragment VpsColorVariantWithProducts on ShopVPSColorVariant {
    ...VpsColorVariant
    products {
      ...ProductVariantBaseProduct
      media(limit: 1) {
        ...ProductMedia
      }
    }
  }
  ${ProductVariantBaseProduct}
  ${VpsColorVariant}
  ${ProductMedia}
`;

export const ProductCategoryInfoBoxParts = gql`
  fragment ProductCategoryInfoBoxParts on InfoBox {
    id
    name
    title
    text
    detailPage {
      id
      slug
      path
    }
    linkLabel
    icon {
      id
      thumbUrl
    }
  }
`;

export const BaseProductParts = gql`
  fragment BaseProductParts on ShopConfigurableProduct {
    _id
    status
    tags
    vpsBrandCode

    media(limit: 50, tags: $mediaTags) {
      ...ProductMedia
    }
    variations {
      _id
      key
      texts {
        _id
        title
      }
    }

    vpsPrimaryAssortmentPath {
      vpsLeafLink {
        assortmentTexts {
          _id
          title
        }
      }
      assortmentProduct {
        _id

        assortment {
          _id
          productManager {
            ...EmployeeFragment
          }
          productInfoBoxes {
            ...ProductCategoryInfoBoxParts
          }
        }
      }
    }
    vpsAttachments(limit: 50) {
      _id
      file {
        _id
        url
        name
        type
      }
      texts {
        title
      }
    }
    texts {
      ...ProductTextsParts
    }
    vpsActiveColorVariants {
      ...VpsColorVariantWithProducts
    }
    vpsAttributes {
      key
      label
      value
      isProxy
    }

    ... on ShopConfigurableProduct {
      ...BaseProductNavItemParts
      vpsYoutubeLinks
    }
    vpsBrandAssortmentPath {
      ...ProductNaviItemPathParts
    }
  }

  ${VpsColorVariantWithProducts}
  ${ProductTextsParts}
  ${ProductVariantBaseProduct}
  ${ProductMedia}
  ${ProductNaviItemPathParts}
  ${BaseProductNavItemParts}
  ${ProductCategoryInfoBoxParts}
  ${EmployeeFragment}
`;

export const BaseProductTileParts = gql`
  fragment BaseProductTileParts on ShopConfigurableProduct {
    _id

    vpsPrimaryAssortmentPath {
      assortmentProduct {
        _id
        assortment {
          _id
          texts {
            _id
            title
          }
        }
      }
    }

    texts {
      _id
      slug
      title
      brand
    }
    ... on ShopConfigurableProduct {
      vpsActiveColorVariants {
        ...VpsColorVariantWithProducts
      }
    }
  }
  ${VpsColorVariantWithProducts}
`;

export const AssortmentTextsParts = gql`
  fragment AssortmentTextsParts on ShopAssortmentTexts {
    # don't fetch the id here, we inline it
    title
    subtitle
    slug
  }
`;

export const AssortmentContentParts = gql`
  fragment AssortmentContentParts on AssortmentContent {
    id
    headerImage {
      focalPoint {
        x
        y
      }
      thumbUrl(width: 2048)
    }
    htmlTitle
    meta {
      description
    }
    social {
      description

      title
    }

    intro
    contentBottom
  }
`;

export const AssortmentBaseParts = gql`
  fragment AssortmentBaseParts on ShopAssortment {
    _id
    vpsType

    texts {
      ...AssortmentTextsParts
    }
  }
  ${AssortmentTextsParts}
`;
export const AssortmentNavitemParts = gql`
  fragment AssortmentNavitemParts on ShopAssortment {
    ...AssortmentBaseParts
    childrenCount
  }
  ${AssortmentBaseParts}
`;
export const AssortmentParts = gql`
  fragment AssortmentParts on ShopAssortment {
    ...AssortmentNavitemParts
    content {
      ...AssortmentContentParts
    }
    vpsCanonicalAssortment {
      _id
    }
  }
  ${AssortmentNavitemParts}
  ${AssortmentContentParts}
`;

export const AssortmentWithChildrenParts = gql`
  fragment AssortmentWithChildrenParts on ShopAssortment {
    _id
    ...AssortmentParts
    children {
      ...AssortmentParts
    }
  }
  ${AssortmentParts}
`;

export const DispatchesForShippingParts = gql`
  fragment DispatchesForShippingParts on ShopSimpleProduct {
    dispatchesForShipping: simulatedDispatches(deliveryProviderType: SHIPPING) {
      _id
      warehousingProvider {
        _id
        vpsBranchTitle
        vpsIsCentralWarehouse
      }
      earliestDelivery
    }
  }
`;

export const DispatchesForPickupParts = gql`
  fragment DispatchesForPickupParts on ShopSimpleProduct {
    dispatchesForPickup: simulatedDispatches(deliveryProviderType: PICKUP) {
      _id
      warehousingProvider {
        _id
        vpsBranchTitle
      }
      earliestDelivery
    }
  }
`;
