import type { Action, Thunk } from "easy-peasy";
import { action, thunk } from "easy-peasy";
import gql from "graphql-tag";
import type { AppModel, Injections } from "../../../lib/types";
import type { ShopUserProfileInput } from "../../../types/global-types";
import { ProfileParts } from "../../checkout/queries";
import updateContactAndBillingAddress from "../../checkout/queries/updateContactAndBillingAddress";
import type { Me } from "../hooks/types/Me";
import { GET_ME } from "../hooks/useMe";
import type {
  ChangePassword,
  ChangePasswordVariables,
} from "./types/ChangePassword";
import type { CreateUser, CreateUserVariables } from "./types/CreateUser";
import type { DeleteEmail, DeleteEmailVariables } from "./types/DeleteEmail";
import type {
  ForgotPassword,
  ForgotPasswordVariables,
} from "./types/ForgotPassword";
import type { Login, LoginVariables } from "./types/Login";
import type { LoginAsGuest } from "./types/LoginAsGuest";
import type { Logout, LogoutVariables } from "./types/Logout";
import type {
  ResetPassword,
  ResetPasswordVariables,
} from "./types/ResetPassword";
import type {
  SendEmailVerificationEmail,
  SendEmailVerificationEmailVariables,
} from "./types/SendEmailVerificationEmail";
import type {
  UpdateProfile,
  UpdateProfileVariables,
} from "./types/UpdateProfile";
import type { VerifyEmail, VerifyEmailVariables } from "./types/VerifyEmail";

/* 📌 ACTION-CREATORS */
interface UserState {
  isNewlyRegistered: boolean;
  hasLoggedInDuringCheckout: boolean;
  setIsNewlyRegistered: Action<UserState>;
  setHasLoggedInDuringCheckout: Action<UserState>;
  showI18nKeys: boolean;
  logoutInProgress: boolean;
}

const initialState = {
  /* 📌 INITIAL-STATE */
  isNewlyRegistered: false,
  hasLoggedInDuringCheckout: false,
  showI18nKeys: false,
  logoutInProgress: false,
};

export interface UserModel extends UserState {
  signup: Thunk<
    UserModel,
    {
      email: string;
      salutation: string;
      lastName: string;
      firstName: string;
      company: string;
      addressLine: string;
      postalCode: string;
      city: string;
      countryCode: string;
      phoneMobile: string;
      birthday: Date;
      password: string;
    },
    Injections,
    AppModel
  >;
  loginWithPassword: Thunk<
    UserModel,
    {
      email: string;
      password: string;
    },
    Injections,
    AppModel
  >;
  loginAsGuest: Thunk<UserModel, void, Injections, AppModel>;
  logout: Thunk<UserModel, void, Injections, AppModel>;

  forgotPassword: Thunk<UserModel, { email: string }, Injections, AppModel>;
  resetPassword: Thunk<
    UserModel,
    { token: string; newPlainPassword: string },
    Injections,
    AppModel
  >;
  changePassword: Thunk<
    UserModel,
    { oldPlainPassword: string; newPlainPassword: string },
    Injections,
    AppModel
  >;
  verifyEmail: Thunk<UserModel, { token: string }, Injections, AppModel>;
  sendEmailVerificationEmail: Thunk<
    UserModel,
    { email: string },
    Injections,
    AppModel
  >;
  deleteEmail: Thunk<UserModel, { email: string }, Injections, AppModel>;
  updateProfile: Thunk<
    UserModel,
    { profile: ShopUserProfileInput },
    Injections,
    AppModel
  >;

  setShowI18nKeys: Action<UserModel, boolean>;
  setLogoutInProgress: Action<UserModel, boolean>;
}

const model: UserModel = {
  ...initialState,
  /* 📌 ACTIONS */
  setIsNewlyRegistered: action((state) => {
    state.isNewlyRegistered = true;
  }),
  setHasLoggedInDuringCheckout: action((state) => {
    state.hasLoggedInDuringCheckout = true;
  }),
  setShowI18nKeys: action((state, show: boolean) => {
    state.showI18nKeys = show;
  }),
  setLogoutInProgress: action((state, inProgress: boolean) => {
    state.logoutInProgress = inProgress;
  }),

  /* 📌 THUNKS */
  signup: thunk(
    async (
      actions,
      {
        email,
        salutation,
        lastName,
        firstName,
        company,
        addressLine,
        postalCode,
        city,
        countryCode,
        phoneMobile,
        birthday,
        password,
      },
      { injections: { apollo } },
    ) => {
      const profile = {
        phoneMobile,
        birthday,
        address: {
          salutation,
          lastName,
          firstName,
          company,
          addressLine,
          postalCode,
          city,
          countryCode,
        },
      };

      actions.setIsNewlyRegistered();

      const result = await apollo.mutate<CreateUser, CreateUserVariables>({
        mutation: gql`
          mutation CreateUser(
            $email: String
            $plainPassword: String
            $profile: ShopUserProfileInput
          ) {
            createUser(
              email: $email
              plainPassword: $plainPassword
              profile: $profile
            ) {
              id
              token
              tokenExpires
            }
          }
        `,
        variables: { email, profile, plainPassword: password },
      });

      if (result.data.createUser) {
        // unfortunatly, unchained does not set the billingAdress for us when creating a user, so we have to do it manually
        await updateContactAndBillingAddress(apollo, {
          contact: {
            emailAddress: email,
            telNumber: profile.phoneMobile,
          },
          billingAddress: profile.address,
        });
        const { id } = result.data.createUser;
        await apollo.resetStore();

        return id;
      }

      return null;
    },
  ),

  loginWithPassword: thunk(
    async (actions, payload, { injections: { apollo } }) => {
      if (typeof window !== "undefined") {
        const isOnCheckout =
          window.location?.pathname?.startsWith("/shop/checkout");
        if (isOnCheckout) {
          actions.setHasLoggedInDuringCheckout();
        }
      }

      await apollo.mutate<Login, LoginVariables>({
        mutation: gql`
          mutation Login($email: String, $plainPassword: String) {
            loginWithPassword(email: $email, plainPassword: $plainPassword) {
              id
              token
              tokenExpires
              user {
                _id
                cart {
                  _id
                }
              }
            }
          }
        `,
        variables: { email: payload.email, plainPassword: payload.password },
      });
      await apollo.resetStore();

      return null;
    },
  ),

  loginAsGuest: thunk(async (_, __, { injections: { apollo } }) => {
    const currentUser = apollo.readQuery<Me>({
      query: GET_ME,
    })?.me;

    if (currentUser?.isGuest) {
      return null; // do nothing
    }

    const wasLoggedIn = Boolean(currentUser);
    await apollo.mutate<LoginAsGuest, void>({
      mutation: gql`
        mutation LoginAsGuest {
          loginAsGuest {
            id
            token
            tokenExpires
            user {
              _id
              cart {
                _id
              }
            }
          }
        }
      `,
      refetchQueries: [
        {
          query: GET_ME,
        },
      ],
    });
    if (wasLoggedIn) await apollo.resetStore();

    return null;
  }),

  logout: thunk(async (actions, _, { getState, injections: { apollo } }) => {
    if (getState().logoutInProgress) {
      // skip
      return;
    }
    actions.setLogoutInProgress(true);
    try {
      await apollo.mutate<Logout, LogoutVariables>({
        mutation: gql`
          mutation Logout($token: String!) {
            logout(token: $token) {
              success
            }
          }
        `,
        variables: {
          token: "empty", // empty string unfortunatly throws error in unchained if cookie is no longer there
        },
      });
      console.log("reset store");
      await apollo.resetStore();
    } finally {
      actions.setLogoutInProgress(false);
    }

    return null;
  }),
  forgotPassword: thunk(async (_, { email }, { injections: { apollo } }) => {
    const response = await apollo.mutate<
      ForgotPassword,
      ForgotPasswordVariables
    >({
      mutation: gql`
        mutation ForgotPassword($email: String!) {
          forgotPassword(email: $email) {
            success
          }
        }
      `,
      variables: {
        email,
      },
    });

    return response?.data?.forgotPassword?.success;
  }),
  resetPassword: thunk(
    async (_, { token, newPlainPassword }, { injections: { apollo } }) => {
      await apollo.mutate<ResetPassword, ResetPasswordVariables>({
        mutation: gql`
          mutation ResetPassword($token: String!, $newPlainPassword: String!) {
            resetPassword(token: $token, newPlainPassword: $newPlainPassword) {
              id
              token
              tokenExpires
            }
          }
        `,
        variables: {
          token,
          newPlainPassword,
        },
      });
      await apollo.resetStore();
    },
  ),
  changePassword: thunk(
    async (
      _,
      { oldPlainPassword, newPlainPassword },
      { injections: { apollo } },
    ) => {
      await apollo.mutate<ChangePassword, ChangePasswordVariables>({
        mutation: gql`
          mutation ChangePassword(
            $oldPlainPassword: String!
            $newPlainPassword: String!
          ) {
            changePassword(
              oldPlainPassword: $oldPlainPassword
              newPlainPassword: $newPlainPassword
            ) {
              success
            }
          }
        `,
        variables: {
          oldPlainPassword,
          newPlainPassword,
        },
      });
    },
  ),
  verifyEmail: thunk(async (_, { token }, { injections: { apollo } }) => {
    await apollo.mutate<VerifyEmail, VerifyEmailVariables>({
      mutation: gql`
        mutation VerifyEmail($token: String!) {
          verifyEmail(token: $token) {
            token
            tokenExpires
          }
        }
      `,
      variables: {
        token,
      },
    });
    await apollo.resetStore();
  }),
  sendEmailVerificationEmail: thunk(
    async (_, { email }, { injections: { apollo } }) => {
      await apollo.mutate<
        SendEmailVerificationEmail,
        SendEmailVerificationEmailVariables
      >({
        mutation: gql`
          mutation SendEmailVerificationEmail($email: String!) {
            sendVerificationEmail(email: $email) {
              success
            }
          }
        `,
        variables: {
          email,
        },
      });
    },
  ),
  deleteEmail: thunk(async (_, { email }, { injections: { apollo } }) => {
    await apollo.mutate<DeleteEmail, DeleteEmailVariables>({
      mutation: gql`
        mutation DeleteEmail($email: String!) {
          removeEmail(email: $email) {
            _id
            ...ProfileParts
          }
        }
        ${ProfileParts}
      `,
      variables: {
        email,
      },
    });
  }),
  updateProfile: thunk(async (_, { profile }, { injections: { apollo } }) => {
    await apollo.mutate<UpdateProfile, UpdateProfileVariables>({
      variables: {
        profile,
      },
      mutation: gql`
        mutation UpdateProfile($profile: ShopUserProfileInput!) {
          updateUserProfile(profile: $profile) {
            _id
            ...ProfileParts
          }
        }
        ${ProfileParts}
      `,
    });
  }),
};

export default model;
