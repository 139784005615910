import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { CartParts } from "../../cart/queries";

import type { UseCart, UseCartVariables } from "./types/UseCart";

export const USE_CART = gql`
  query UseCart($orderId: ID!) {
    order(orderId: $orderId) {
      ...CartParts
    }
  }
  ${CartParts}
`;

const useCart = (orderId?: string) => {
  const { data, previousData, error } = useQuery<UseCart, UseCartVariables>(
    USE_CART,
    {
      fetchPolicy: "cache-and-network",
      ssr: false,
      variables: { orderId },
      skip: !orderId,
    },
  );
  const cachedOrder = (data ?? previousData)?.order;
  const order = cachedOrder?._id === orderId ? cachedOrder : null;

  return {
    order,
    error,
    loading: !order,
  };
};

export default useCart;
