import React from "react";
import styled from "styled-components";
import { selectColor } from "../../../utils/themeUtils";

const SVG = styled.svg`
  width: 16px;
  height: 16px;
  g {
    stroke: ${selectColor((p) => p.color)};
  }
`;

export interface CloseIconProps {
  style?: React.CSSProperties;
  className?: string;
  onClick?: any;
  color?: any;
  props?: any;
}

const CloseIcon: React.FC<CloseIconProps> = ({
  props,
  style,
  className,
  color = "black",
}) => (
  <SVG
    viewBox="0 0 18 18"
    style={style}
    className={className}
    color={color}
    onClick={props}
  >
    <g strokeWidth="2" strokeLinecap="square" fill="none" fillRule="evenodd">
      <path d="M1.44 1.44l15.12 14.77M16.56 1.44L1.44 16.21" />
    </g>
  </SVG>
);

export default CloseIcon;
