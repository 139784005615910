import React from "react";
import styled, { keyframes } from "styled-components";
import type { Color } from "../../../config/theme";
import { selectColor } from "../../../utils/themeUtils";

// thx https://loading.io/css/
const animation = keyframes`

    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;
const Base = styled.div<{ size: number; color: Color }>`
  display: inline-block;
  position: relative;
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${(p) => p.size}px;
    height: ${(p) => p.size}px;
    border: ${(p) => Math.round(p.size / 10)}px solid
      ${selectColor((p) => p.color)};
    border-radius: 50%;
    animation: ${animation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${selectColor((p) => p.color)} transparent transparent
      transparent;
  }
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

export interface ProcessingIndicatorProps {
  style?: React.CSSProperties;
  className?: string;
  color?: Color;
  size?: number;
}

const ProcessingIndicator: React.FC<ProcessingIndicatorProps> = ({
  style,
  className,
  color = "petrol",
  size = 100,
}) => (
  <Base style={style} className={className} color={color} size={size}>
    <div />
    <div />
    <div />
    <div />
  </Base>
);

export const ProcessingIndicatorWrapper: React.FC<ProcessingIndicatorProps> =
  styled.div.attrs<ProcessingIndicatorProps>((props) => ({
    children: <ProcessingIndicator size={props.size} color={props.color} />,
  }))`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  ` as any;

export default ProcessingIndicator;
