import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { ProfileOrderParts } from "../../order/queries";
import { ProfileParts } from "../queries";
import type {
  UseProfileAndOrder,
  UseProfileAndOrderVariables,
} from "./types/UseProfileAndOrder";

export const USE_PROFILE = gql`
  query UseProfileAndOrder($orderId: ID!) {
    me {
      ...ProfileParts
    }
    order(orderId: $orderId) {
      ...ProfileOrderParts
    }
  }
  ${ProfileParts}
  ${ProfileOrderParts}
`;

// FIXME: this hook is a bit irritating to use, can we come up with something better?
const useProfileAndOrder = (orderId: string) => {
  const { data, previousData, error, loading } = useQuery<
    UseProfileAndOrder,
    UseProfileAndOrderVariables
  >(USE_PROFILE, {
    variables: { orderId },
    fetchPolicy: "cache-and-network",
    ssr: false,
  });
  const theData = data ?? previousData;
  const me = theData?.me;
  const order = theData?.order;

  return { me, order, error, loading };
};

export default useProfileAndOrder;
