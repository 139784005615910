import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig() || {};

export const getIsomorphicAPIUrl = (path = ""): string => {
  if (
    (path === "/graphql" || path === "") &&
    process?.env?.STORYBOOK_GRAPHQL_ENDPOINT
  ) {
    return process.env.STORYBOOK_GRAPHQL_ENDPOINT;
  }

  const url =
    typeof window === "undefined"
      ? process.env.GRAPHQL_INTERNAL_ENDPOINT ||
        publicRuntimeConfig?.GRAPHQL_ENDPOINT ||
        process.env.GRAPHQL_ENDPOINT ||
        "http://localhost:4000"
      : publicRuntimeConfig?.GRAPHQL_ENDPOINT;

  return `${url}${path}`;
};
