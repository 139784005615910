export const SHOP_PATH = "/shop";

export const INTERNET_EXPLORER_PATH = "/ie";

export const CART_PATH = `${SHOP_PATH}/cart`;

export const CHECKOUT_PATH = `${SHOP_PATH}/checkout`;

export const CHECKOUT_CONFIRM_PATH = (orderId: string) =>
  `${CHECKOUT_PATH}/confirmation?orderId=${orderId}`;

export const CHECKOUT_STEPS = {
  step1: `${CHECKOUT_PATH}/step1`,
  step2: `${CHECKOUT_PATH}/step2`,
  step3: `${CHECKOUT_PATH}/step3`,
  summary: `${CHECKOUT_PATH}/summary`,
};

export const CATEGORY_PAGE_PATH = `${SHOP_PATH}/category`;

export const SEARCH_PAGE_PATH = `${SHOP_PATH}/search`;

export const BRAND_BASE_PATH = `${SHOP_PATH}/marken`;

export const PROFILE_PATH = `${SHOP_PATH}/profil`;

export const NEWSLETTER_CONFIRMATION_PATH = `/velonews-bestaetigung`;
