import gql from "graphql-tag";
import {
  CartParts,
  DeliveryParts,
  FullDeliveryBlockingItemsParts,
} from "../../cart/queries";
import { ShopAddressParts } from "../../shop/queries";

export const ProfileOrderParts = gql`
  fragment ProfileOrderParts on ShopOrder {
    _id
    contact {
      telNumber
      emailAddress
    }
    billingAddress {
      ...ShopAddressParts
    }

    vpsBirthdayIsRequired
    vpsDeliveryAddress {
      ...ShopAddressParts
    }
  }

  ${ShopAddressParts}
`;

export const BulkOrderParts = gql`
  fragment BulkOrderParts on ShopOrder {
    ...CartParts
    ...DeliveryParts
    ...FullDeliveryBlockingItemsParts
  }
  ${CartParts}
  ${DeliveryParts}
  ${FullDeliveryBlockingItemsParts}
`;

export const VPSReturnableItemParts = gql`
  fragment VPSReturnableItemParts on ShopVPSReturnableItem {
    _id
    quantity
    articleNumber
    title
    paymentType
    ourReference
    returnableStatus
    price {
      currency
      amount
    }
    discount {
      currency
      amount
    }
    product {
      _id
      texts {
        _id
        slug
      }
    }
  }
`;
