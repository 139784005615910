import type { Thunk } from "easy-peasy";
import { thunk } from "easy-peasy";
import gql from "graphql-tag";

import {
  PaymentParts,
  SupportedPaymentProvidersParts,
} from "../../cart/queries";
import type {
  SetOrderPaymentProvider,
  SetOrderPaymentProviderVariables,
} from "./types/SetOrderPaymentProvider";
import type {
  AddCartDiscount,
  AddCartDiscountVariables,
} from "./types/AddCartDiscount";
import type {
  RemoveCartDiscount,
  RemoveCartDiscountVariables,
} from "./types/RemoveCartDiscount";
import { GetDiscounts } from "../hooks/useDiscounts";
import { USE_CART } from "../hooks/useCart";
import { USE_PAYMENT } from "../hooks/usePayment";
import type { AppModel, Injections } from "../../../lib/types";

export interface CheckoutState {}

/* 📌 ACTION-CREATORS */

const initialState = {
  /* 📌 INITIAL-STATE */
};

export interface CheckoutModel extends CheckoutState {
  updateOrderPaymentProvider: Thunk<
    CheckoutModel,
    { orderId: string; paymentProviderId: string },
    Injections,
    AppModel
  >;
  doCreditCheck: Thunk<
    CheckoutModel,
    { orderId: string },
    Injections,
    AppModel
  >;
  addCartDiscount: Thunk<
    CheckoutModel,
    { orderId: string; code: string },
    Injections,
    AppModel
  >;
  removeCartDiscount: Thunk<
    CheckoutModel,
    { orderId: string; discountId: string },
    Injections,
    AppModel
  >;
}

const model: CheckoutModel = {
  ...initialState,
  /* 📌 ACTION-REDUCERS */
  updateOrderPaymentProvider: thunk(
    async (_, variables, { injections: { apollo } }) => {
      await apollo.mutate<
        SetOrderPaymentProvider,
        SetOrderPaymentProviderVariables
      >({
        mutation: gql`
          mutation SetOrderPaymentProvider(
            $orderId: ID!
            $paymentProviderId: ID!
          ) {
            setOrderPaymentProvider(
              orderId: $orderId
              paymentProviderId: $paymentProviderId
            ) {
              _id
              ...PaymentParts
              ...SupportedPaymentProvidersParts
            }
          }
          ${PaymentParts}
          ${SupportedPaymentProvidersParts}
        `,
        variables,
      });
    },
  ),
  addCartDiscount: thunk(async (_, variables, { injections: { apollo } }) => {
    await apollo.mutate<AddCartDiscount, AddCartDiscountVariables>({
      mutation: gql`
        mutation AddCartDiscount($orderId: ID, $code: String!) {
          addCartDiscount(orderId: $orderId, code: $code) {
            _id
          }
        }
      `,
      variables,
      refetchQueries: [
        {
          query: GetDiscounts,
          variables,
        },
        {
          query: USE_CART,
          variables,
        },
        {
          query: USE_PAYMENT,
          variables,
        },
      ],
    });
  }),
  doCreditCheck: thunk(async (_, { orderId }, { injections: { apollo } }) => {
    return await apollo
      .mutate({
        mutation: gql`
          mutation DoCreditCheck($orderId: ID!) {
            vpsDoCreditCheck(orderId: $orderId) {
              _id
              vpsCanPayByInvoice
            }
          }
        `,
        variables: {
          orderId,
        },
      })
      .then((r) => r.data?.vpsDoCreditCheck?.vpsCanPayByInvoice ?? true);
  }),
  removeCartDiscount: thunk(
    async (_, variables, { injections: { apollo } }) => {
      await apollo.mutate<RemoveCartDiscount, RemoveCartDiscountVariables>({
        mutation: gql`
          mutation RemoveCartDiscount($discountId: ID!) {
            removeCartDiscount(discountId: $discountId) {
              _id
            }
          }
        `,
        variables,
        refetchQueries: [
          {
            query: GetDiscounts,
            variables,
          },
          {
            query: USE_CART,
            variables,
          },
          {
            query: USE_PAYMENT,
            variables,
          },
        ],
      });
    },
  ),
};

export default model;
