import Head from "next/head";
import React from "react";
import { initApollo } from "./initApollo";

const withApolloClient = (App) => {
  return class Apollo extends React.Component {
    public static displayName = "withApollo(App)";

    public static async getInitialProps(ctx) {
      const { AppTree } = ctx;

      let appProps = {};
      if (App.getInitialProps) {
        appProps = await App.getInitialProps(ctx);
      }

      // Run all GraphQL queries in the component tree
      // and extract the resulting data
      const apollo = initApollo(undefined, {
        ssrRequest: ctx.ctx?.req,
      });
      if (typeof window === "undefined") {
        // When redirecting, the response is finished.
        // No point in continuing to render
        if (ctx.res && ctx.res.finished) {
          return appProps;
        }

        try {
          // We don't want to have this in our client bundle.
          const { getDataFromTree } = await import("@apollo/client/react/ssr");

          // Run all GraphQL queries
          await getDataFromTree(
            <AppTree {...appProps} apolloClient={apollo} />,
          );
        } catch (error) {
          // Prevent Apollo Client GraphQL errors from crashing SSR.
          // Handle them in components via the data.error prop:
          // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
          console.error("Error while running `getDataFromTree`", error);
        }
      }

      // Extract query data from the Apollo store
      const apolloState = apollo.cache.extract();

      return {
        ...appProps,
        apolloState,
      };
    }
    public apolloClient: any;
    constructor(props) {
      super(props);

      this.apolloClient = initApollo(props.apolloState);
    }

    public render() {
      return <App apolloClient={this.apolloClient} {...this.props} />;
    }
  };
};

export default withApolloClient;
