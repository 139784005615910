import type { Thunk } from "easy-peasy";
import { thunk } from "easy-peasy";
import gql from "graphql-tag";
import type { Injections, AppModel } from "../../../lib/types";
import type {
  RespondToProductReviewInput,
  ShopProductReviewInput,
} from "../../../types/global-types";
import { GetReviews, ProductReviewParts } from "../queries";
import type {
  CreateProductReview,
  CreateProductReviewVariables,
} from "./types/CreateProductReview";
import type {
  RemoveProductReview,
  RemoveProductReviewVariables,
} from "./types/RemoveProductReview";
import type {
  RespondToProductReview,
  RespondToProductReviewVariables,
} from "./types/RespondToProductReview";

export interface ReviewModel {
  addReview: Thunk<
    ReviewModel,
    { productId: string; review: ShopProductReviewInput },
    Injections,
    AppModel
  >;
  deleteReview: Thunk<
    ReviewModel,
    { productId: string; productReviewId: string },
    Injections,
    AppModel
  >;
  respondToReview: Thunk<
    ReviewModel,
    { input: RespondToProductReviewInput },
    Injections,
    AppModel
  >;
}

const AddReviewMutation = gql`
  mutation CreateProductReview(
    $productId: ID!
    $review: ShopProductReviewInput!
  ) {
    createProductReview(productId: $productId, productReview: $review) {
      product {
        _id
        reviews {
          ...ProductReviewParts
        }
      }
    }
  }
  ${ProductReviewParts}
`;

const DeleteReviewMutation = gql`
  mutation RemoveProductReview($productReviewId: ID!) {
    removeProductReview(productReviewId: $productReviewId) {
      _id
    }
  }
`;

const RespondToReviewMutation = gql`
  mutation RespondToProductReview($input: RespondToProductReviewInput!) {
    respondToProductReview(input: $input) {
      productReviewId
      text
      createdAt
      updatedAt
    }
  }
`;

const model: ReviewModel = {
  addReview: thunk((actions, { productId, review }, { injections }) => {
    return injections.apollo.mutate<
      CreateProductReview,
      CreateProductReviewVariables
    >({
      mutation: AddReviewMutation,
      variables: {
        productId,
        review,
      },
    });
  }),
  deleteReview: thunk(
    (actions, { productId, productReviewId }, { injections }) => {
      return injections.apollo.mutate<
        RemoveProductReview,
        RemoveProductReviewVariables
      >({
        mutation: DeleteReviewMutation,
        variables: {
          productReviewId,
        },
        refetchQueries: [
          {
            query: GetReviews,
            variables: {
              productId,
            },
          },
        ],
      });
    },
  ),
  respondToReview: thunk((actions, { input }, { injections }) => {
    return injections.apollo.mutate<
      RespondToProductReview,
      RespondToProductReviewVariables
    >({
      mutation: RespondToReviewMutation,
      variables: {
        input,
      },
    });
  }),
};

export default model;
