import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { PageBaseParts } from "../queries";
import type {
  UsePageBaseProps,
  UsePageBasePropsVariables,
} from "./types/UsePageBaseProps";

const QUERY = gql`
  query UsePageBaseProps($path: String) {
    page(path: $path) {
      ...PageBaseParts
    }
  }
  ${PageBaseParts}
`;

const usePageBaseProps = (path: string) => {
  const { data, error, loading } = useQuery<
    UsePageBaseProps,
    UsePageBasePropsVariables
  >(QUERY, {
    variables: { path },
    skip: !path,
  });

  return {
    error,
    loading,
    page: data?.page ?? null,
  };
};

export default usePageBaseProps;
