import gql from "graphql-tag";

export const ShopAddressParts = gql`
  fragment ShopAddressParts on ShopAddress {
    firstName
    lastName
    company
    addressLine
    postalCode
    countryCode
    regionCode
    city
    salutation
  }
`;
