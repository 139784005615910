import type { Thunk } from "easy-peasy";
import { thunk } from "easy-peasy";
import gql from "graphql-tag";
import type { AppModel, Injections } from "../../../lib/types";
import { mutateWithEnforcedUser } from "../../cart/model";
import { MyVisitedProductsFragment } from "../queries";
import type { VisitProduct, VisitProductVariables } from "./types/VisitProduct";

/* 📌 ACTION-CREATORS */

const initialState = {
  /* 📌 INITIAL-STATE */
};

export type VisitProductModel = {
  visitProduct: Thunk<
    VisitProductModel,
    { productId: string },
    Injections,
    AppModel
  >;
};

const VISIT_PRODUCT_MUTATION = gql`
  mutation VisitProduct($productId: ID!) {
    vpsVisitProduct(productId: $productId) {
      user {
        ...MyVisitedProductsFragment
      }
    }
  }
  ${MyVisitedProductsFragment}
`;

const model: VisitProductModel = {
  ...initialState,
  /* 📌 ACTIONS */

  /* 📌 THUNKS */
  visitProduct: thunk(async (_, { productId }, helpers) => {
    await mutateWithEnforcedUser<VisitProduct, VisitProductVariables>(helpers, {
      mutation: VISIT_PRODUCT_MUTATION,
      variables: { productId },
    });
  }),
};

export default model;
