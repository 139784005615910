export interface ProductSlug {
  _id: string;
  texts?: {
    slug: string | null;
  } | null;
}
const makeFullProductSlug = (product: ProductSlug) => {
  return `${product.texts?.slug}-${product._id}`;
};

export default makeFullProductSlug;
