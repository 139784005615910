import type { Palette } from "../../../config/theme";
import usePageBaseProps from "./usePageBaseProps";

const usePalette = (path: string) => {
  const { page } = usePageBaseProps(path);
  const palette = page?.palette ?? "shop";

  return palette as Palette;
};

export default usePalette;
