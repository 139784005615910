import React from "react";
import { ThemeProvider } from "styled-components";
import type { Palette, Theme } from "../../../config/theme";
import { palettes } from "../../../config/theme/colors";

interface Props {
  paletteKey: Palette;
}
const PaletteThemeProvider: React.FC<Props & any> = ({
  paletteKey,
  ...props
}) => (
  <ThemeProvider
    theme={(t: Theme) => ({
      ...t,
      colors: {
        ...t.colors,
        currentPalette: palettes[paletteKey] || palettes.shop,
        paletteKey,
      },
    })}
    {...props}
  />
);

export default PaletteThemeProvider;
