/* 📌 IMPORTS */
import delivery from "./delivery";

import payment from "./payment";
import placeOrder from "./placeOrder";
import profile from "./profile";

const reducers = {
  /* 📌 SYMBOLS */
  delivery,

  payment,
  placeOrder,
  profile,
};

export default reducers;
