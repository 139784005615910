import type { Thunk, Action } from "easy-peasy";
import { action } from "easy-peasy";
import { thunk } from "easy-peasy";
import gql from "graphql-tag";
import type { Injections, AppModel } from "../../../lib/types";
import { ProfileParts } from "../../checkout/queries";
import type {
  SubmitOrderReturnVariables,
  SubmitOrderReturn,
} from "./types/SubmitOrderReturn";

export interface ReturnsState {
  hasProcessingReturns: boolean;
}

/* 📌 ACTION-CREATORS */

const initialState: ReturnsState = {
  /* 📌 INITIAL-STATE */
  hasProcessingReturns: false,
};

export interface ReturnsModel extends ReturnsState {
  submitOrderReturn: Thunk<
    ReturnsModel,
    SubmitOrderReturnVariables,
    Injections,
    AppModel
  >;
  setHasProcessing: Action<ReturnsModel, boolean>;
}

const model: ReturnsModel = {
  ...initialState,
  setHasProcessing: action((state, payload) => {
    state.hasProcessingReturns = payload;
  }),
  submitOrderReturn: thunk(
    async (actions, variables, { injections: { apollo } }) => {
      actions.setHasProcessing(true);
      const result = await apollo.mutate<
        SubmitOrderReturn,
        SubmitOrderReturnVariables
      >({
        mutation: gql`
          mutation SubmitOrderReturn(
            $historicalOrderId: String!
            $paymentType: String!
            $positions: [ShopVPSSubmitOrderReturnPositionInput!]!
          ) {
            vpsSubmitOrderReturn(
              historicalOrderId: $historicalOrderId
              paymentType: $paymentType
              positions: $positions
            ) {
              ...ProfileParts
            }
          }
          ${ProfileParts}
        `,
        variables: variables,
      });

      return result;
    },
  ),
};

export default model;
