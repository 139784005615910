const DESKTOP_COL_WIDTH = 65;
const DESKTOP_GUTTER_WIDTH = 30;
const DESKTOP_HEADER_MAIN_ACTIONS_HEIGHT = 65;
const DESKTOP_MAIN_NAV_HEIGHT = 55;

const NUMBER_OF_COLUMNS = 12;
const sizes = {
  mobilePagePadding: 15,

  headerBenefitsHeight: 35,
  mobileHeaderContextNavHeight: 35,
  desktopHeaderContextNavHeight: 40,
  mobileNavItemHeight: 55,
  mobileHeaderMainActionsHeight: 50,
  desktopHeaderMainActionsHeight: DESKTOP_HEADER_MAIN_ACTIONS_HEIGHT,
  desktopMainNavHeight: DESKTOP_MAIN_NAV_HEIGHT,

  desktopPagePadding: DESKTOP_GUTTER_WIDTH,
  desktopGridGutter: DESKTOP_GUTTER_WIDTH,
  desktopGrid: {
    col: DESKTOP_COL_WIDTH,
    gutter: DESKTOP_GUTTER_WIDTH,
    numberOfColumns: NUMBER_OF_COLUMNS,
  },

  maxPageWidth: 1440,
  anchorLinkOffset: 180,

  maxContentWidth:
    NUMBER_OF_COLUMNS * DESKTOP_COL_WIDTH +
    (NUMBER_OF_COLUMNS - 1) * DESKTOP_GUTTER_WIDTH,
  maxSlateWidth: 920,

  buttonBorderRadius: 5,
  boxBorderRadius: 5,
  teaserMinHeight: 430,
  teaserMinHeightSmall: 350,

  dialogPadding: 15,
  filterPanelWidth: 255,

  imageGalleryThumbWidth: 100,

  imageGalleryThumbsWrapperHeight: 72,
  imageGalleryFullScreenSideMargin: 50,

  pluginOptionalTopMargin: 50,
  pluginMobileMargin: 30,
  pluginDesktopMargin: 80,
  introBottomMargin: 40,
};

export default sizes;
