// see https://github.com/apollographql/react-apollo/issues/3595#issuecomment-577586255

import { useMemo } from "react";
import { getApolloContext, ApolloProvider } from "@apollo/client";

const CustomApolloProvider = ({ client, children }) => {
  const ApolloContext = getApolloContext();
  const value = useMemo(() => ({ client }), [client]);
  return (
    <ApolloContext.Provider value={value}>{children}</ApolloContext.Provider>
  );
};
// use normal for ssr
export default process.browser ? CustomApolloProvider : ApolloProvider;
