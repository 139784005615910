//import "../utils/wdyr";

import "../fonts.css";

// see https://github.com/zeit/next-plugins/issues/282#issuecomment-453753044 :-/
import "@react-page/editor/lib/index.css";
import "@react-page/plugins-slate/lib/index.css";
import type { ApolloClient } from "@apollo/client";

import type { Store } from "easy-peasy";
import { StoreProvider } from "easy-peasy";
import NextApp from "next/app";
import Head from "next/head";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-image-gallery/styles/css/image-gallery.css";

import { ThemeProvider } from "styled-components";
import GlobalStyles from "../config/GlobalStyles";
import { appWithTranslation } from "../config/i18n";
import theme from "../config/theme";

import CustomApolloProvider from "../lib/CustomApolloProvider";
import type { AppState } from "../lib/types";
import { PreviousRouteProvider } from "../lib/PreviousRouteProvider";
import withApolloClient from "../lib/withApolloClient";
import withReduxStore from "../lib/withReduxStore";
import SentryUserIdSetter from "../modules/core/components/SentryUserIdSetter";
import GridLines from "../modules/debug/components/GridLines";
import BasePageLayout from "../modules/layout/components/BasePageLayout";
import AdminApolloProvider from "../modules/admin/AdminApolloProvider";
import SeoBaseHead from "../modules/core/components/SeoBaseHead";

// this is just for firefox <= 68 💩, only adds 1kb to bundle size though
import "core-js/features/promise";
import I18nAdminKeySetter from "../modules/profile/components/I18nAdminKeySetter";
import GlobalScanner from "../modules/scanner/components/GlobalScanner";
import dynamic from "next/dynamic";

import("../modules/core/lib/initializeSentry")
  .then((s) => s.default())
  .catch((e) => {
    // ignore
  });

// whenever dom is ready
if (process.browser) {
  import("smoothscroll-polyfill").then((p) => p.polyfill());
  import("intersection-observer");
}

const getDefaultBasePageLayout = (p) => <BasePageLayout>{p}</BasePageLayout>;

class Application extends NextApp<
  {
    reduxStore: Store<AppState>;
    apolloClient: ApolloClient<any>;
  },
  {
    getBasePageLayout: any;
  }
> {
  public static async getInitialProps({ Component, ctx }) {
    let pageProps: any = {};
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return {
      pageProps: {
        ...pageProps,
        namespacesRequired: [
          ...(pageProps.namespacesRequired || []),
          "common",
          "cart",

          "navigation",
          "shop",
        ],
      },
    };
  }
  public render() {
    const { pageProps, reduxStore, apolloClient } = this.props;

    const Component = this.props.Component;
    const getBasePageLayout =
      (Component as any).getBasePageLayout ?? getDefaultBasePageLayout;

    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
        </Head>

        <CustomApolloProvider client={apolloClient}>
          <AdminApolloProvider>
            <StoreProvider store={reduxStore}>
              <ThemeProvider theme={theme}>
                <PreviousRouteProvider>
                  <SeoBaseHead />
                  <GlobalStyles />
                  <GridLines />
                  <SentryUserIdSetter />
                  <I18nAdminKeySetter />
                  <GlobalScanner />
                  {getBasePageLayout(<Component {...pageProps} />)}
                </PreviousRouteProvider>
              </ThemeProvider>
            </StoreProvider>
          </AdminApolloProvider>
        </CustomApolloProvider>
      </>
    );
  }
}

const App = withApolloClient(withReduxStore(appWithTranslation(Application)));

export default App;
