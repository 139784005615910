import type { Thunk } from "easy-peasy";
import { thunk } from "easy-peasy";
import type { Injections, AppModel } from "../../../lib/types";
import { mutateWithEnforcedUser } from "../../cart/model";
import { BookmarkParts } from "../queries";
import type {
  BookmarkProduct,
  BookmarkProductVariables,
} from "./types/BookmarkProduct";
import gql from "graphql-tag";

/* 📌 ACTION-CREATORS */
interface BookmarksState {}

const initialState = {
  /* 📌 INITIAL-STATE */
};

export interface BookmarksModel extends BookmarksState {
  bookmarkProduct: Thunk<
    BookmarksModel,
    { productId: string; bookmarked: boolean },
    Injections,
    AppModel
  >;
}

const model: BookmarksModel = {
  ...initialState,
  /* 📌 ACTIONS */

  /* 📌 THUNKS */
  bookmarkProduct: thunk(async (_, { productId, bookmarked }, helpers) => {
    await mutateWithEnforcedUser<BookmarkProduct, BookmarkProductVariables>(
      helpers,
      {
        mutation: gql`
          mutation BookmarkProduct($productId: ID!, $bookmarked: Boolean!) {
            bookmark(productId: $productId, bookmarked: $bookmarked) {
              user {
                _id
                bookmarks {
                  ...BookmarkParts
                }
              }
            }
          }
          ${BookmarkParts}
        `,
        variables: { productId, bookmarked },
      },
    );
  }),
};

export default model;
