import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import type { UseBaseConfig } from "./types/UseBaseConfig";

// add everything that is small and used often
// for config parts that are seldom used, write its own query
const QUERY = gql`
  query UseBaseConfig {
    config {
      id
      htmlTitleTemplate
      productMetaDescriptionTemplate
      velosizingVersion
      reviewsVersion
      assortmentRangeMode
      assortmentRangeOpenInfoAutomaticallyMobile
      assortmentRangeOpenInfoAutomaticallyDesktop

      footer {
        content
        newsletter
      }
      headerBenefits {
        benefit1
        benefit2
        benefit3
      }
      contact {
        whatsapp
        phone
        openingHours
        hint
        hintLinkUrl
        hintLinkText
      }
    }
  }
`;

const useBaseConfig = () => {
  return useQuery<UseBaseConfig>(QUERY);
};

export default useBaseConfig;
