import { useRouter } from "next/router";
import { useMemo } from "react";
import type { Crumb } from "../utils/makeBreadcrumbPaths";
import makeBreadcrumbPaths from "../utils/makeBreadcrumbPaths";

export type { Crumb };
const useBreadcrumbPaths = (overriddenPath?: string) => {
  const defaultPath = useRouter()?.asPath;
  const routePath = (overriddenPath ?? defaultPath)?.split("?")[0] as string;
  return useMemo(() => {
    return makeBreadcrumbPaths(routePath);
  }, [routePath]);
};
export default useBreadcrumbPaths;
