import fetch from "isomorphic-unfetch";
import { once } from "lodash-es";
import type { Options } from "./createApolloLink";
import createApolloLink from "./createApolloLink";

import possibleTypes from "../possibleTypes.json";
import type { NormalizedCacheObject } from "@apollo/client";
import { InMemoryCache, ApolloClient } from "@apollo/client";

// Polyfill fetch() on the server (used by apollo-client)
if (!process.browser) {
  global.fetch = fetch;
}

const create = (initialState?: NormalizedCacheObject, options?: Options) => {
  return new ApolloClient({
    connectToDevTools: process.browser,
    assumeImmutableResults: true,
    ssrMode: !process.browser, // Disables forceFetch on the server (so queries are only run once)
    link: createApolloLink(options),
    defaultOptions: {
      query: {
        errorPolicy: "all",
      },
      watchQuery: {
        errorPolicy: "all",
      },
    },
    cache: new InMemoryCache({
      possibleTypes,
      typePolicies: {
        ShopConfigurableProduct: {
          fields: {
            vpsPrimaryAssortmentPath: {
              merge(existing, incoming, options) {
                return options.mergeObjects(existing, incoming);
              },
            },
          },
        },
        ShopSimpleProduct: {
          fields: {
            vpsPrimaryAssortmentPath: {
              merge(existing, incoming, options) {
                return options.mergeObjects(existing, incoming);
              },
            },
          },
        },
        ShopAssortment: {
          fields: {
            texts: {
              merge(existing, incoming, options) {
                return options.mergeObjects(existing, incoming);
              },
            },
          },
        },
        ShopOrder: {
          fields: {
            items: {
              merge(existing, incoming, options) {
                return incoming;
              },
            },
            supportedDeliveryProviders: {
              merge(existing, incoming, options) {
                return incoming;
              },
            },
          },
        },
        Config: {
          fields: {
            footer: {
              merge(existing, incoming, options) {
                return options.mergeObjects(existing, incoming);
              },
            },
          },
        },

        Query: {
          fields: {
            searchProducts: {
              merge(existing, incoming, options) {
                return options.mergeObjects(existing, incoming);
              },
            },
            // the following functions are for cache-redirection
            // see https://www.apollographql.com/docs/react/caching/advanced-topics/#cache-redirects-using-field-policy-read-functions
            // there are a pure optimization and could be removed, but that leads to some additional queries being made
            page: {
              read(ref, { args, cache, toReference }) {
                if (ref) return ref;
                // workaround for https://github.com/apollographql/apollo-client/discussions/7574 ...
                const rawData = (cache as any).data?.data;
                const existingId = args.id
                  ? `Page:${args.id}`
                  : args.path && rawData
                  ? Object.keys(rawData).find(
                      (key) =>
                        key.startsWith("Page") &&
                        rawData[key].path === args.path,
                    )
                  : undefined;

                return existingId && toReference(existingId);
              },
            },
            assortment: {
              read(ref, { args, cache, toReference }) {
                if (ref) return ref;
                // workaround for https://github.com/apollographql/apollo-client/discussions/7574 ...
                const rawData = (cache as any).data?.data;
                const existingId = args.assortmentId
                  ? `ShopAssortment:${args.assortmentId}`
                  : args.slug && rawData
                  ? Object.keys(rawData).find(
                      (key) =>
                        key.startsWith("ShopAssortment") &&
                        rawData[key].texts?.slug === args.slug,
                    )
                  : undefined;

                return existingId && toReference(existingId);
              },
            },
            product: {
              read(ref, { args, toReference }) {
                if (ref) return ref;

                return args.productId
                  ? toReference({
                      __typename: "ShopConfigurableProduct",
                      id: args.productId,
                    })
                  : undefined;
              },
            },
          },
        },
      },
    }).restore(initialState || {}),
  });
};

export const initApollo: typeof create = !process.browser
  ? create
  : once(create);
