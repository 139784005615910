import type { Thunk } from "easy-peasy";
import { thunk } from "easy-peasy";
import gql from "graphql-tag";
import type { AppModel, Injections } from "../../../lib/types";
import { mutateWithEnforcedUser } from "../../cart/model";
import { PREFERENCES_FRAGMENT } from "../hooks/useMe";
import { MyVisitedProductsFragment } from "../queries";
import type {
  UpdatePreferences,
  UpdatePreferencesVariables,
} from "./types/UpdatePreferences";
import type { VisitProduct, VisitProductVariables } from "./types/VisitProduct";

/* 📌 ACTION-CREATORS */

const initialState = {
  /* 📌 INITIAL-STATE */
};

export type PreferencesModel = {
  setPreferences: Thunk<
    PreferencesModel,
    UpdatePreferencesVariables["preferences"],
    Injections,
    AppModel
  >;
};

const UPDATE_PREFERENCES_MUTATION = gql`
  mutation UpdatePreferences($preferences: ShopVpsUserPreferenceInput) {
    vpsSetUserPreferences(preferences: $preferences) {
      _id
      vpsPreferences {
        ...Preferences
      }
    }
  }
  ${PREFERENCES_FRAGMENT}
`;

const model: PreferencesModel = {
  ...initialState,
  /* 📌 ACTIONS */

  /* 📌 THUNKS */
  setPreferences: thunk(async (_, preferences, helpers) => {
    await mutateWithEnforcedUser<UpdatePreferences, UpdatePreferencesVariables>(
      helpers,
      {
        mutation: UPDATE_PREFERENCES_MUTATION,
        variables: { preferences },
      },
    );
  }),
};

export default model;
