import { useStoreActions, useStoreState } from "../../../lib/model";
import { useEffect } from "react";
import { useKeyPress } from "react-use";
import useMe from "../hooks/useMe";
import { ShopRoleAction } from "../../../types/global-types";

const I18nAdminKeySetter = () => {
  const { me } = useMe();

  const show = useStoreState((s) => s.profile.user.showI18nKeys);
  const setShow = useStoreActions((s) => s.profile.user.setShowI18nKeys);
  const [pressed] = useKeyPress((e) => {
    return (e.key === "Alt" && e.metaKey) || (e.key === "Meta" && e.altKey);
  });

  const isContentEditor =
    me?.allowedActions?.includes(ShopRoleAction.manageContent) ?? false;

  useEffect(() => {
    if (pressed) {
      if (show) {
        setShow(false);
      } else if (isContentEditor) {
        setShow(true);
      }
    }
    // toggle when pressed and if is content editor
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressed, isContentEditor]);

  return null;
};

export default I18nAdminKeySetter;
