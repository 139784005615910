import React from "react";
import styled from "styled-components";
import type { Color } from "../../../config/theme";
import { selectColor } from "../../../utils/themeUtils";

const SVG = styled.svg<{ color: Color; colorHover?: Color }>`
  height: 14px;
  & path {
    stroke: ${selectColor((p) => p.color)};
  }
  &:hover path {
    stroke: ${selectColor((p) => p.colorHover ?? p.color)};
  }
`;

export interface NextIconProps {
  style?: React.CSSProperties;
  className?: string;
  color?: Color;
  colorHover?: Color;
}

const NextIcon: React.FC<NextIconProps> = ({
  style,
  className,
  color = "grey",
  colorHover = null,
}) => (
  <SVG
    viewBox="0 0 8 14"
    style={style}
    className={className}
    color={color}
    colorHover={colorHover}
  >
    <path d="M1 13l6-6-6-6" strokeWidth="1.5" fill="none" fillRule="evenodd" />
  </SVG>
);

export default NextIcon;
