import type { Thunk } from "easy-peasy";
import { thunk } from "easy-peasy";
import gql from "graphql-tag";
import { omit } from "lodash-es";
import type { Injections, AppModel } from "../../../lib/types";
import { CartParts, DeliveryParts, PaymentParts } from "../../cart/queries";
import type {
  UseProfileAndOrder,
  UseProfileAndOrderVariables,
} from "../hooks/types/UseProfileAndOrder";
import { USE_PROFILE } from "../hooks/useProfileAndOrder";
import { ProfileParts } from "../queries";
import updateContactAndBillingAddress from "../queries/updateContactAndBillingAddress";
import type { PlaceOrder } from "./types/PlaceOrder";

export interface CheckoutState {}

/* 📌 ACTION-CREATORS */

const initialState = {
  /* 📌 INITIAL-STATE */
};

export interface CheckoutModel extends CheckoutState {
  preparePlaceOrder: Thunk<CheckoutModel, string, Injections, AppModel>;
  placeInvoiceOrder: Thunk<CheckoutModel, string, Injections, AppModel>;
}

const model: CheckoutModel = {
  ...initialState,
  /* 📌 ACTION-REDUCERS */
  preparePlaceOrder: thunk(async (_, orderId, { injections: { apollo } }) => {
    const { data: profileData } = await apollo.query<
      UseProfileAndOrder,
      UseProfileAndOrderVariables
    >({
      query: USE_PROFILE,
      variables: { orderId },
    });

    if (!profileData.me.isGuest) {
      // when user is logged in, sync order contact with profile info
      await updateContactAndBillingAddress(apollo, {
        contact: {
          emailAddress: profileData.me.primaryEmail.address,
          telNumber:
            profileData.me.profile.phoneMobile ??
            profileData.me.profile.phoneOffice ??
            profileData.me.profile.phonePrivate,
        },
        billingAddress: profileData.order.billingAddress?.addressLine
          ? undefined
          : omit(profileData.me.profile.address, ["__typename"]),
      });
    }
  }),
  placeInvoiceOrder: thunk(
    async ({ preparePlaceOrder }, orderId, { injections: { apollo } }) => {
      await preparePlaceOrder(orderId);
      const newOrder = await apollo.mutate<PlaceOrder>({
        variables: { orderId },
        mutation: gql`
          mutation PlaceOrder($orderId: ID!) {
            checkoutCart(orderId: $orderId) {
              ...CartParts
              ...DeliveryParts
              ...PaymentParts
              orderNumber
              user {
                ...ProfileParts
              }
            }
          }
          ${CartParts}
          ${DeliveryParts}
          ${PaymentParts}
          ${ProfileParts}
        `,
      });

      return newOrder;
    },
  ),
};

export default model;
