import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { ShopOrderDiscountTrigger } from "../../../types/global-types";
import { DiscountParts } from "../../cart/queries";
import type {
  GetDiscounts as GetDiscountsProps,
  GetDiscountsVariables,
} from "./types/GetDiscounts";

export const GetDiscounts = gql`
  query GetDiscounts($orderId: ID!) {
    order(orderId: $orderId) {
      _id
      discounts {
        ...DiscountParts
      }
    }
  }
  ${DiscountParts}
`;

const useDiscounts = (orderId?: string) => {
  const { data, error, loading } = useQuery<
    GetDiscountsProps,
    GetDiscountsVariables
  >(GetDiscounts, {
    variables: { orderId },
    skip: !orderId,
    fetchPolicy: "cache-and-network",
    ssr: false,
  });

  return {
    discounts: data?.order?.discounts?.filter(
      (d) => d.trigger === ShopOrderDiscountTrigger.USER,
    ),
    error,
    loading,
  };
};

export default useDiscounts;
