import { onError } from "@apollo/client/link/error";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { sha256 } from "crypto-hash";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";

import type { Operation } from "@apollo/client";
import { ApolloLink } from "@apollo/client";
import { getIsomorphicAPIUrl } from "./getIsomorphicAPIUrl";

const getEntryMeta = (operation: Operation) => ({
  type: (
    operation.query.definitions.find((defn) => (defn as any).operation) as any
  ).operation,
  variables: JSON.stringify(operation.variables, null, 2),
});
const sentryLink = new ApolloLink((operation, forward) => {
  const observer = forward(operation);

  const meta = getEntryMeta(operation);
  return observer.map((data) => {
    import("../modules/core/lib/sentry").then((e) => {
      const { Severity, addBreadcrumb } = e.default();
      addBreadcrumb({
        category: "graphql",
        message: operation.operationName,
        data: {
          ...meta,
        },
        level: Severity.Debug,
      });
    });

    return data;
  });
});

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  const meta = getEntryMeta(operation);
  if (graphQLErrors) {
    import("../modules/core/lib/sentry").then((e) => {
      const { Severity, addBreadcrumb } = e.default();
      addBreadcrumb({
        category: "graphql",
        message: operation.operationName,
        data: {
          ...meta,
          errors: graphQLErrors,
        },
        level: Severity.Error,
      });
    });
  }

  if (networkError) {
    import("../modules/core/lib/sentry").then((e) => {
      const { Severity, addBreadcrumb } = e.default();
      addBreadcrumb({
        category: "graphql",
        message: operation.operationName,
        data: {
          ...meta,
          errors: networkError,
        },
        level: Severity.Error,
      });
    });
  }
});

export type Options = {
  ssrRequest?: any;
};
const createApolloLink = (options?: Options) => {
  // enable live data in storybook
  const uploadLink = createUploadLink({
    uri: getIsomorphicAPIUrl(),
    credentials: "include",
  });

  const ssrContextLink = setContext(() => {
    const isSSR = !process.browser;
    if (isSSR) {
      // using ssrRequest.cookies is funky, its not set for some request, but its always set in the header
      // so we use that directly
      const loginToken =
        options?.ssrRequest?.headers?.cookie
          ?.split("; ")
          .find((r) => r.startsWith("meteor_login_token"))
          ?.split("=")[1] ?? null;
      return {
        headers: {
          // we mark SSR requsts with a special header
          ["x-is-ssr"]: 1,
          // we want to force refresh cache for admins only, so we have to know the original user
          ["x-ssr-initiator-user-token"]: loginToken,
        },
      };
    }
    return {};
  });
  return ApolloLink.from([
    ssrContextLink,
    errorLink,
    sentryLink,
    createPersistedQueryLink({
      sha256,
    }),

    uploadLink,
  ]);
};

export default createApolloLink;
