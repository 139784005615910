import { useRouter } from "next/router";
import React, { createContext, useState, useEffect, useContext } from "react";

interface Route {
  pathname?: string;
  asPath?: string;
  query?: { [key: string]: string | string[] };
}

const PreviousRouteContext = createContext<Route>({});

export const PreviousRouteProvider: React.FC = ({ children }) => {
  const { pathname, asPath, query } = useRouter();
  const [previousRoute, setPreviousRoute] = useState<Route>({});
  const [currentRoute, setCurrentRoute] = useState<Route>({});

  useEffect(() => {
    setPreviousRoute(currentRoute);
    setCurrentRoute({
      pathname,
      asPath,
      query,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, asPath]);

  return (
    <PreviousRouteContext.Provider value={previousRoute}>
      {children}
    </PreviousRouteContext.Provider>
  );
};

export function usePreviousRoute() {
  const context = useContext(PreviousRouteContext);
  return context;
}
