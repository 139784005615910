import { useEffect, useState } from "react";

const useMinProcessing = (minDurationMs: number = 1000) => {
  const [processing, setProcessing] = useState(false);
  const [startTime, setStartTime] = useState<number>(null);

  useEffect(() => {
    let handle;
    if (processing && !startTime) {
      setStartTime(new Date().getTime());
    } else {
      if (startTime) {
        const elapsedTime = new Date().getTime() - startTime;
        if (elapsedTime < minDurationMs) {
          handle = setTimeout(() => {
            setStartTime(null);
          }, minDurationMs - elapsedTime);
        } else {
          setStartTime(null);
        }
      }
    }
    return () => {
      clearTimeout(handle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processing]);

  return {
    minProcessing: !!startTime,
    setMinProcessing: setProcessing,
  };
};

export default useMinProcessing;
