import type { NextSeoProps } from "next-seo";
import dynamic from "next/dynamic";

import { useRouter } from "next/router";
import type { ComponentType } from "react";

// lazy load to make it a bit smaller
const NextSeo: ComponentType<NextSeoProps> = dynamic(() =>
  import("next-seo").then((f) => f.NextSeo),
);

const Seo: React.FC<NextSeoProps> = (props) => {
  // set noIndex when url contains filters or sorting

  const noIndex = Object.keys(useRouter().query).some(
    (f) => f.startsWith("filter_") || f === "order_by",
  );
  return <NextSeo {...props} nofollow={noIndex} noindex={noIndex} />;
};

export default Seo;
