import gql from "graphql-tag";
import {
  DispatchesForShippingParts,
  VariantProductNavItemParts,
} from "../../product/queries";
import { ShopAddressParts } from "../../shop/queries";

const DeliveryProviderParts = gql`
  fragment DeliveryProviderParts on ShopDeliveryProvider {
    _id
    type
    interface {
      _id
    }
    configuration
  }
`;

export const SupportedDeliveryProvidersParts = gql`
  fragment SupportedDeliveryProvidersParts on ShopOrder {
    supportedDeliveryProviders {
      ...DeliveryProviderParts
    }
  }
  ${DeliveryProviderParts}
`;

export const SimulatedDeliveryPricesParts = gql`
  fragment SimulatedDeliveryPricesParts on ShopDeliveryProvider {
    _id
    PRIORITY: simulatedPrice(
      orderId: $orderId
      context: { meta: { activeShippingMethod: "PRIORITY" } }
    ) {
      amount
      currency
    }
    ECONOMY: simulatedPrice(
      orderId: $orderId
      context: { meta: { activeShippingMethod: "ECONOMY" } }
    ) {
      amount
      currency
    }
    EXPRESS: simulatedPrice(
      orderId: $orderId
      context: { meta: { activeShippingMethod: "EXPRESS" } }
    ) {
      amount
      currency
    }
    SATHURDAY: simulatedPrice(
      orderId: $orderId
      context: { meta: { activeShippingMethod: "SATHURDAY" } }
    ) {
      amount
      currency
    }
    VELO: simulatedPrice(
      orderId: $orderId
      context: { meta: { activeShippingMethod: "VELO" } }
    ) {
      amount
      currency
    }
  }
`;

export const DeliveryParts = gql`
  fragment DeliveryParts on ShopOrder {
    delivery {
      _id

      provider {
        ...DeliveryProviderParts
      }
      ... on ShopOrderDeliveryShipping {
        vpsIsBulkOrder
        vpsActiveShippingMethod
        vpsShippingMethodDefinitions {
          _id
          price {
            amount
            currency
          }
        }
      }
      ... on ShopOrderDeliveryPickUp {
        activePickUpLocation {
          _id
          name
          address {
            ...ShopAddressParts
          }
        }
        pickUpLocations {
          _id
          name
          address {
            ...ShopAddressParts
          }
        }
      }
    }
  }
  ${DeliveryProviderParts}
  ${ShopAddressParts}
`;

export const FullDeliveryBlockingItemsParts = gql`
  fragment FullDeliveryBlockingItemsParts on ShopOrder {
    vpsFullDeliveryBlockingItems {
      product {
        _id
      }
    }
    items {
      product {
        ... on ShopSimpleProduct {
          ...DispatchesForShippingParts
        }
      }
    }
  }
  ${DispatchesForShippingParts}
`;

export const PaymentProviderParts = gql`
  fragment PaymentProviderParts on ShopPaymentProvider {
    _id
    type
    interface {
      _id
    }
    configuration
  }
`;

export const PaymentParts = gql`
  fragment PaymentParts on ShopOrder {
    payment {
      _id
      provider {
        ...PaymentProviderParts
      }
    }
  }

  ${PaymentProviderParts}
`;

export const SupportedPaymentProvidersParts = gql`
  fragment SupportedPaymentProvidersParts on ShopOrder {
    supportedPaymentProviders {
      ...PaymentProviderParts
    }
  }

  ${PaymentProviderParts}
`;

export const CartItemParts = gql`
  fragment CartItemParts on ShopOrderItem {
    _id
    quantity
    total {
      amount
      currency
    }
    unitPrice {
      amount
      currency
    }
    discounts {
      _id
      vpsComment
      orderDiscount {
        _id
        interface {
          _id
        }
      }
    }

    product {
      _id
      texts {
        _id
        title
        brand
        slug
      }

      ... on ShopSimpleProduct {
        vpsLegacyArticleNumber
        vpsAvailability
        vpsTotalQuantityInStock
        ...VariantProductNavItemParts
      }
    }
  }
  ${VariantProductNavItemParts}
`;

export const CartParts = gql`
  fragment CartParts on ShopOrder {
    _id
    total {
      amount
      currency
    }

    delivery {
      _id
      ... on ShopOrderDeliveryShipping {
        vpsActiveShippingMethod
      }
      ... on ShopOrderDeliveryPickUp {
        activePickUpLocation {
          _id
          name
        }
      }
    }
    deliveryTotal: total(category: DELIVERY) {
      amount
      currency
    }
    items {
      ...CartItemParts
    }
  }
  ${CartItemParts}
`;

export const DiscountParts = gql`
  fragment DiscountParts on ShopOrderDiscount {
    _id
    code
    trigger
    total {
      amount
      currency
    }
    interface {
      _id
      label
    }
  }
`;
