import { css } from "styled-components";
import mediaQueries from "../../utils/mediaQueries";

const FONT_FAMILY_GEO_GROTESQUE = "Geogrotesque, sans-serif";
const FONT_FAMILY_OPEN_SANS = "'Open Sans', sans-serif";

const LIGHT = 300;

const MEDIUM = 500;
export const SEMI_BOLD = 600;
const BOLD = 700;

const fonts = {
  extraLarge: css`
    font-size: 38px;
    letter-spacing: 0.9px;
    line-height: 44px;
    font-family: ${FONT_FAMILY_GEO_GROTESQUE};
    font-weight: ${MEDIUM};
    ${mediaQueries.desktop`
      font-size: 46px;
      letter-spacing: 1.2px;
      line-height: 58px;
      `};
  `,
  large: css`
    font-size: 32px;
    letter-spacing: 0.8px;
    line-height: 38px;
    font-family: ${FONT_FAMILY_GEO_GROTESQUE};
    font-weight: ${MEDIUM};
    ${mediaQueries.desktop`
      font-size: 36px;
      letter-spacing: 1px;
      line-height: 40px;
    `};
  `,
  medium: css`
    font-size: 24px;
    letter-spacing: 0.5px;
    line-height: 28px;
    font-family: ${FONT_FAMILY_GEO_GROTESQUE};
    font-weight: ${MEDIUM};
  `,
  // seldom used, only in landing page störer currently
  mediumSmall: css`
    font-size: 18px;
    line-height: 23px;
    font-family: ${FONT_FAMILY_GEO_GROTESQUE};
    font-weight: ${SEMI_BOLD};
    letter-spacing: 0.39px;
  `,
  small: css`
    font-size: 14px;
    line-height: 21px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${BOLD};
  `,

  extraSmall: css`
    font-size: 9px;
    line-height: 30px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${BOLD};
  `,
  quotes: css`
    font-size: 26px;
    letter-spacing: 0.56px;
    line-height: 36px;
    font-family: ${FONT_FAMILY_GEO_GROTESQUE};
    font-weight: ${MEDIUM};
  `,

  contentPluginTitle: css`
    font-size: 15px;
    line-height: 24px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${BOLD};
  `,
  categoryHeader: css`
    font-size: 32px;
    letter-spacing: 0.8px;
    line-height: 40px;
    font-family: ${FONT_FAMILY_GEO_GROTESQUE};
    font-weight: ${MEDIUM};
    ${mediaQueries.desktop`
      font-size: 48px;
      letter-spacing: 1.4px;
      line-height: 60px;
    `};
  `,
  searchField: css`
    font-size: 16px;
    line-height: 21px;
    ${mediaQueries.desktop`
     font-size: 14px;
     line-height: 21px;
    `};

    font-family: ${FONT_FAMILY_OPEN_SANS};
  `,
  searchResultCategorytitle: css`
    font-size: 15px;
    line-height: 26px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${BOLD};
  `,
  searchResultLink: css`
    font-size: 15px;
    line-height: 26px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${SEMI_BOLD};
  `,
  productTileTitle: css`
    font-size: 15px;
    line-height: 24px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${LIGHT};
  `,
  productPrice: css`
    font-size: 15px;
    line-height: 24px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${BOLD};
  `,
  productVariantPrice: css`
    font-size: 15px;
    line-height: 24px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${SEMI_BOLD};
  `,
  productPriceOriginal: css`
    font-size: 13px;
    line-height: 24px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${BOLD};
  `,
  productDetailLink: css`
    font-size: 15px;
    line-height: 24px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${SEMI_BOLD};
  `,
  textDetails: css`
    font-size: 14px;
    line-height: 21px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
  `,
  textDetailsSemiBold: css`
    font-size: 14px;
    line-height: 21px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${SEMI_BOLD};
  `,
  textDetailsBold: css`
    font-size: 14px;
    line-height: 21px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${BOLD};
  `,
  input: css`
    font-size: 16px;
    line-height: 28px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    ${mediaQueries.desktop`
      font-size: 14px;
    `};
  `,
  tabTitle: css`
    font-size: 15px;
    line-height: 24px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${SEMI_BOLD};
  `,
  textContent: css`
    font-size: 17px;
    line-height: 26px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
  `,
  textLead: css`
    font-size: 17px;
    line-height: 26px;
    font-family: ${FONT_FAMILY_OPEN_SANS};

    ${mediaQueries.desktop`
      font-size: 24px;
      line-height: 34px;
   `};
  `,
  textHighlight: css`
    font-size: 17px;
    line-height: 26px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${SEMI_BOLD};
  `,
  breadCrumbs: css`
    font-size: 13px;
    line-height: 18px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${SEMI_BOLD};
  `,
  brandCategory: css`
    font-size: 18px;
    line-height: 30px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${SEMI_BOLD};
  `,
  leftSubNavigationHeading: css`
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-size: 15px;
    line-height: 24px;
    font-weight: ${BOLD};
  `,
  sidebarCategoryItem: css`
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-size: 15px;
    line-height: 24px;
    font-weight: ${SEMI_BOLD};
  `,
  subCategoryItem: css`
    font-size: 18px;
    line-height: 26px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${SEMI_BOLD};
  `,
  mainNavigation: css`
    text-transform: uppercase;
    font-size: 17px;
    line-height: 29px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${BOLD};
    letter-spacing: 0.49px;
    ${mediaQueries.desktop`
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.4px;
   `};
  `,
  megaDropdown: css`
    font-size: 14px;
    line-height: 27px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${BOLD};
    letter-spacing: 0.49px;
  `,
  leftSubNavigation: css`
    font-size: 15px;
    line-height: 24px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${SEMI_BOLD};
  `,

  cartCount: css`
    font-size: 13px;
    line-height: 15px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${BOLD};
    letter-spacing: 0.49px;
  `,
  popupTable: css`
    font-size: 13px;
    line-height: 18px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
  `,
  popupHighlight: css`
    font-size: 13px;
    line-height: 18px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${BOLD};
  `,
  contextNavigation: css`
    font-size: 12px;
    line-height: 17px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${BOLD};
    letter-spacing: 0.3px;
    ${mediaQueries.desktop`
     font-size: 12px;
     line-height: 24px;

  `};
  `,

  metaNavigation: css`
    font-size: 11px;
    line-height: 17px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${SEMI_BOLD};
    letter-spacing: 0.3px;
    text-transform: uppercase;
    ${mediaQueries.desktop`

     line-height: 24px;

  `};
  `,

  imageCaption: css`
    font-size: 14px;
    line-height: 21px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
  `,

  productDiscount: css`
    font-family: ${FONT_FAMILY_GEO_GROTESQUE};
    font-size: 12px;
    font-style: italic;
    font-weight: ${SEMI_BOLD}; // we actually don't have this font-weight, but looks correctly
    letter-spacing: 0.85px;
    line-height: 24px;
    text-align: center;
  `,

  productDiscountLarge: css`
    font-family: ${FONT_FAMILY_GEO_GROTESQUE};
    font-size: 11px;
    font-style: italic;
    font-weight: ${SEMI_BOLD}; // we actually don't have this font-weight, but looks correctly
    letter-spacing: 0.85px;
    line-height: 24px;
    text-align: center;

    ${mediaQueries.tablet`
      font-size: 16px;
    `}
  `,

  productTag: css`
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-size: 13px;
    line-height: 18px;
  `,
  formError: css`
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-size: 13px;
    line-height: 18px;
  `,
  inlineError: css`
    font-size: 15px;
    line-height: 24px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
  `,

  checkoutSubtitle: css`
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-size: 15px;

    line-height: 24px;

    ${mediaQueries.desktop`
      font-size: 18px;
      line-height: 30px;
    `}
  `,
  checkoutText: css`
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-size: 15px;
    line-height: 24px;
  `,
  button: css`
    font-size: 15px;
    line-height: 24px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${BOLD};
  `,
  buttonSmall: css`
    font-size: 15px;
    line-height: 24px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${BOLD};
    ${mediaQueries.desktop`
      font-size: 13px;
      line-height: 24px;
    `};
  `,
  buttonExtraSmall: css`
    font-size: 13px;
    line-height: 24px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${BOLD};
  `,

  filterTitle: css`
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${SEMI_BOLD};
    font-size: 15px;
  `,

  tipNumber: css`
    font-size: 15px;
    line-height: 24px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
  `,
  tipTitle: css`
    font-size: 26px;
    line-height: 30px;
    font-family: ${FONT_FAMILY_GEO_GROTESQUE};
    font-weight: ${MEDIUM};

    ${mediaQueries.desktop`
      font-size: 28px;
      line-height: 32px;
    `}
  `,
  tipTag: css`
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-size: 13px;
    line-height: 18px;
  `,
  filterList: css`
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-size: 17px;
    line-height: 29px;
    font-weight: ${SEMI_BOLD};
    ${mediaQueries.desktop`
      font-size: 15px;
      line-height: 20px;

    `}
  `,
  dialogTitle: css`
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-size: 17px;
    line-height: 36px;
    font-weight: ${BOLD};

    ${mediaQueries.desktop`
      font-size: 17px;
      line-height: 24px;
    `}
  `,

  footerTitle: css`
    font-size: 15px;
    line-height: 21px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${BOLD};

    ${mediaQueries.desktop`
      font-size: 12px;
      line-height: 15px;
    `}
  `,
  footerLink: css`
    font-size: 15px;
    line-height: 21px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${MEDIUM};

    ${mediaQueries.desktop`
      font-size: 12px;
      line-height: 15px;
    `}
  `,
  footerSecondary: css`
    font-size: 15px;
    line-height: 18px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${SEMI_BOLD};

    ${mediaQueries.desktop`
      font-size: 12px;
      line-height: 17px;
    `}
  `,
  footerNewsletterTitle: css`
    font-size: 12px;
    line-height: 15px;
    font-family: ${FONT_FAMILY_GEO_GROTESQUE};
    font-weight: ${MEDIUM};

    ${mediaQueries.desktop`
      font-size: 24px;
      letter-spacing: 0.5px;
      line-height: 28px;
    `}
  `,
  footerNewsletterDescription: css`
    font-size: 12px;
    line-height: 17px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${SEMI_BOLD};
  `,
  sandBox: css`
    font-size: 15px;
    line-height: 24px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${SEMI_BOLD};
  `,
  sandBoxTitle: css`
    font-size: 18px;
    line-height: 26px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${SEMI_BOLD};
  `,
  sandBoxDescription: css`
    font-size: 13px;
    line-height: 18px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${SEMI_BOLD};
  `,
  sandBoxButton: css`
    font-size: 13px;
    line-height: 24px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${BOLD};
  `,
  infoBoxTitle: css`
    font-size: 13px;
    line-height: 17px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${SEMI_BOLD};
  `,
  infoBoxText: css`
    font-size: 13px;
    line-height: 17px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
  `,
  captchaTerms: css`
    font-size: 13px;
    line-height: 24px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
  `,
  blogTeaserDate: css`
    font-size: 15px;
    line-height: 24px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${SEMI_BOLD};
  `,
  blogTeaserIntro: css`
    font-size: 15px;
    line-height: 24px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
  `,
  blogTeaserLink: css`
    font-size: 15px;
    line-height: 24px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${SEMI_BOLD};
  `,
  bikeTeaserLink: css`
    font-size: 18px;
    line-height: 30px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${SEMI_BOLD};
  `,
  removableBox: css`
    font-size: 13px;
    line-height: 24px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
    font-weight: ${BOLD};
  `,
  travelReportDate: css`
    font-size: 15px;
    line-height: 24px;
    font-family: ${FONT_FAMILY_OPEN_SANS};
  `,
};

export default fonts;
