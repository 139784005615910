import type {
  ApolloClient,
  FetchResult,
  MutationOptions,
  QueryOptions,
} from "@apollo/client";
import { defaultDataIdFromObject } from "@apollo/client";
import { action, thunk } from "easy-peasy";

import gql from "graphql-tag";
import type { AppThunkHelpers } from "../../../lib/types";
import type { Injections } from "../../../lib/types";
import { CartParts } from "../../cart/queries";
import { USE_DELIVERY } from "../../checkout/hooks/useDelivery";
import { USE_PAYMENT } from "../../checkout/hooks/usePayment";
import type { Me } from "../../profile/hooks/types/Me";
import { GET_ME } from "../../profile/hooks/useMe";
import type { CartParts as CartPartsT } from "../queries/types/CartParts";

import type { CartModel, CartState } from "./types";
import type {
  AddCartProduct,
  AddCartProductVariables,
} from "./types/AddCartProduct";
import type {
  RemoveCartItem,
  RemoveCartItemVariables,
} from "./types/RemoveCartItem";
import type { ShopOrderItemQuantity } from "./types/ShopOrderItemQuantity";
import type {
  UpdateCartItemQuantity,
  UpdateCartItemQuantityVariables,
} from "./types/UpdateCartItemQuantity";

/* 📌 ACTION-CREATORS */

const initialState: CartState = {
  /* 📌 INITIAL-STATE */
  showProductLimitReachedForId: null,
};

const cartRelatedQueries = (
  apollo: ApolloClient<unknown>,
  orderId: string,
): QueryOptions[] => {
  // only refetch USE_DELIVERY and USE_PAYMENT if already in cache
  // reason is that we want to avoid triggering the expensive delivery filter logic on the backend
  // TODO: unchained unfortunatly still does a credit check :-( see https://git.panter.ch/manul/vps/veloplus-shop/-/issues/985#note_282227
  const hasDelivery = Boolean(
    apollo.readQuery({
      query: USE_DELIVERY,
      variables: {
        orderId,
      },
    })?.order,
  );
  const hasPayment = Boolean(
    apollo.readQuery({
      query: USE_PAYMENT,
      variables: {
        orderId,
      },
    })?.order,
  );
  return [
    {
      query: GET_ME,
    },
    ...(hasDelivery
      ? [
          {
            query: USE_DELIVERY,
            variables: { orderId },
          },
        ]
      : []),
    ...(hasPayment
      ? [
          {
            query: USE_PAYMENT,
            variables: { orderId },
          },
        ]
      : []),
  ];
};

export async function mutateWithEnforcedUser<M, TVariables>(
  { dispatch, injections }: AppThunkHelpers<CartModel>,
  options: MutationOptions<M, TVariables>,
): Promise<FetchResult<M>> {
  // check if there is me in the cache, or load it from network
  const hasMe = await injections.apollo
    .query<Me>({
      query: GET_ME,
      fetchPolicy: "cache-first", // this is the default, but its good to be explicit
    })
    .then((r) => Boolean(r.data?.me?._id));
  if (!hasMe) {
    await dispatch.profile.user.loginAsGuest();
  }
  const doIt = async () => injections.apollo.mutate<M, TVariables>(options);
  try {
    return await doIt();
  } catch (e) {
    // try again
    await injections.apollo.resetStore();
    await dispatch.profile.user.loginAsGuest();
    return await doIt();
  }
}

function getCachedOrderItemQuantity(
  {
    injections,
  }: {
    injections: Injections;
  },
  itemId: string,
) {
  const id = defaultDataIdFromObject({
    _id: itemId,
    __typename: "ShopOrderItem",
  });

  const fragment = injections.apollo.readFragment<ShopOrderItemQuantity>({
    id,
    fragment: gql`
      fragment ShopOrderItemQuantity on ShopOrderItem {
        _id
        quantity
      }
    `,
  });

  return fragment?.quantity ?? 0;
}

const getOptimisticCart = (
  orderId: string,
  apolloClient: ApolloClient<unknown>,
) => {
  const cachedCart = apolloClient.readFragment<CartPartsT>({
    fragment: CartParts,
    fragmentName: "CartParts",
    id: `ShopOrder:${orderId}`,
  });
  return cachedCart;
};
const getOptimisticCartWithItemQuantity = (
  orderId: string,
  itemId: string,
  quantity: number,
  apolloClient: ApolloClient<unknown>,
) => {
  const cachedCart = getOptimisticCart(orderId, apolloClient);

  const optimisticItems =
    cachedCart?.items
      .map((i) => {
        if (i._id !== itemId) {
          return i;
        }
        return {
          ...i,
          quantity,
          total: {
            ...i.total,
            amount: quantity * i.unitPrice.amount,
          },
        };
      })
      .filter((i) => i.quantity > 0) ?? [];
  const optimisticOrder: CartPartsT = {
    ...cachedCart,
    items: optimisticItems,
    total: {
      ...cachedCart?.total,
      amount:
        optimisticItems.reduce((sum, item) => sum + item.total.amount, 0) +
        (cachedCart.deliveryTotal.amount ?? 0),
    },
  };
  return optimisticOrder;
};

const model: CartModel = {
  ...initialState,
  setShowProductLimitReachedForId: action((state, payload) => {
    state.showProductLimitReachedForId = payload;
  }),
  /* 📌 ACTION-REDUCERS */
  addProductToCart: thunk(
    async (_, { productId, quantity }, helpers: AppThunkHelpers) => {
      const apollo = helpers.injections.apollo;

      helpers.getStoreActions().cart.setShowProductLimitReachedForId(null);
      try {
        const result = await mutateWithEnforcedUser<
          AddCartProduct,
          AddCartProductVariables
        >(helpers, {
          mutation: gql`
            mutation AddCartProduct($productId: ID!, $quantity: Int! = 1) {
              addCartProduct(productId: $productId, quantity: $quantity) {
                _id
                order {
                  ...CartParts
                }
              }
            }
            ${CartParts}
          `,
          variables: {
            productId,
            quantity,
          },
        });

        // We cant do refetch queries here because of missing orderId, so we do this instead:
        const orderId = result?.data?.addCartProduct?.order?._id;

        if (orderId) {
          cartRelatedQueries(apollo, orderId).forEach((query) =>
            apollo.query({
              ...query,
              fetchPolicy: "network-only",
            }),
          );
        }
        const { pushAddToCartToGoogleAnalytics } = await import("./ga");

        pushAddToCartToGoogleAnalytics(helpers, productId, quantity);

        return null;
      } catch (e) {
        helpers
          .getStoreActions()
          .cart.setShowProductLimitReachedForId(productId);
        throw e;
      }
    },
  ),
  updateCartItemQuantity: thunk(
    async (
      _,
      { orderId, productId, itemId, quantity },
      helpers: AppThunkHelpers,
    ) => {
      const currentQuantity = getCachedOrderItemQuantity(helpers, itemId);
      const quantityDelta = quantity - currentQuantity;
      const apollo = helpers.injections.apollo;
      const optimisticOrder = getOptimisticCartWithItemQuantity(
        orderId,
        itemId,
        quantity,
        apollo,
      );
      helpers.getStoreActions().cart.setShowProductLimitReachedForId(null);
      try {
        await apollo.mutate<
          UpdateCartItemQuantity,
          UpdateCartItemQuantityVariables
        >({
          mutation: gql`
            mutation UpdateCartItemQuantity($itemId: ID!, $quantity: Int! = 1) {
              updateCartItem(itemId: $itemId, quantity: $quantity) {
                _id
                order {
                  ...CartParts
                }
              }
            }
            ${CartParts}
          `,
          variables: {
            itemId,
            quantity,
          },
          errorPolicy: "ignore", // throws sometimes if user is not patient, see https://github.com/unchainedshop/unchained/issues/268
          optimisticResponse: {
            updateCartItem: {
              __typename: "ShopOrderItem",
              _id: "someid",
              order: optimisticOrder,
            },
          },
          refetchQueries: cartRelatedQueries(apollo, orderId),
          awaitRefetchQueries: false,
        });

        if (quantityDelta > 0) {
          const { pushAddToCartToGoogleAnalytics } = await import("./ga");
          pushAddToCartToGoogleAnalytics(helpers, productId, quantityDelta);
        } else if (quantityDelta < 0) {
          const { pushRemoveFromCartToGoogleAnalytics } = await import("./ga");
          pushRemoveFromCartToGoogleAnalytics(
            helpers,
            productId,
            -quantityDelta,
          );
        }

        return null;
      } catch (e) {
        helpers
          .getStoreActions()
          .cart.setShowProductLimitReachedForId(productId);
        throw e;
      }
    },
  ),
  setCartItemQuantity: thunk(
    async (actions, { orderId, productId, itemId, quantity }) => {
      if (quantity <= 0) {
        return;
      }

      return actions.updateCartItemQuantity({
        orderId,
        productId,
        itemId,
        quantity,
      });
    },
  ),
  removeCartItem: thunk(async (_, { orderId, productId, itemId }, helpers) => {
    const quantity = getCachedOrderItemQuantity(helpers, itemId);
    const apollo = helpers.injections.apollo;
    const optimisticOrder = getOptimisticCartWithItemQuantity(
      orderId,
      itemId,
      0,
      apollo,
    );

    await apollo.mutate<RemoveCartItem, RemoveCartItemVariables>({
      mutation: gql`
        mutation RemoveCartItem($itemId: ID!) {
          removeCartItem(itemId: $itemId) {
            _id
            order {
              ...CartParts
            }
          }
        }
        ${CartParts}
      `,
      variables: {
        itemId,
      },
      errorPolicy: "ignore", // throws sometimes if user is not patient, see https://github.com/unchainedshop/unchained/issues/268
      optimisticResponse: {
        removeCartItem: {
          __typename: "ShopOrderItem",
          _id: itemId,
          order: optimisticOrder,
        },
      },
      refetchQueries: cartRelatedQueries(apollo, orderId),
      awaitRefetchQueries: false,
    });

    const { pushRemoveFromCartToGoogleAnalytics } = await import("./ga");

    pushRemoveFromCartToGoogleAnalytics(helpers, productId, quantity);

    return null;
  }),
};

export default model;
