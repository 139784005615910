import React from "react";

export interface HomeIconProps {
  style?: React.CSSProperties;
  className?: string;
}

const HomeIcon: React.FC<HomeIconProps> = ({ style, className }) => (
  <svg
    width="23px"
    height="21px"
    viewBox="0 0 23 21"
    version="1.1"
    style={style}
    className={className}
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <path
          d="M4.92 12.75l7.5-.88v4.76l-1.05.12V13l-5.4.63v3.76l-1.05.12v-4.76zm10.66 1.77c.31-.03.56.17.56.46 0 .3-.25.56-.56.6-.3.03-.55-.17-.55-.47 0-.29.25-.55.55-.59zm-12.37-2.2v7.02l11.1-1.3v-6.4l4.23-.5v6.4l1.72-.2v-7.02l1.05-.13v7.03c.52-.05.94.43.94.93L1.22 20.62c0-.5.41-1.08.93-1.16v-7.02l1.06-.12zm15.05-4.9c0 2.99-4.06 3.44-4.06.47l4.06-.47zM5.8 2.96l4.07-.48-.3 5.96c0 2.97 4.2 2.49 4.2-.5l-.19-5.9 4.2-.49.91 5.82c0 2.96 4.2 2.5 4.2-.5L21.3.91V.02L2.15 2.27v.89L.45 9.5c0 2.98 4.2 2.49 4.2-.5L5.8 2.97zm3.34 5.53c0 2.99-4.06 3.45-4.06.48l4.06-.48z"
          fill="#1D1D1B"
        />
      </g>
    </g>
  </svg>
);

export default HomeIcon;
