export type Crumb = {
  slug: string;
  path: string;
};

const makeBreadcrumbPaths = (fullPath: string = ""): Crumb[] => {
  return (
    fullPath
      .substring(1) // remove inital slash
      .split("#")[0] // remove hash
      .split("?")[0] // remove query string
      .split("/")
      .reduce(
        (acc, slug, index) => [
          ...acc,
          {
            slug,
            path: `${(acc[index - 1] && acc[index - 1].path) || ""}/${slug}`,
          },
        ],
        [],
      ) ?? []
  );
};

export default makeBreadcrumbPaths;
