import type {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ReactElement,
  ReactNode,
} from "react";
import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import type { Color, Font } from "../../../config/theme";
import colors from "../../../config/theme/colors";
import {
  iff,
  selectColor,
  selectFont,
  selectSize,
} from "../../../utils/themeUtils";
import ProcessingIndicator from "./ProcessingIndicator";
import useMinProcessing from "../../core/hooks/useMinProcessing";

type BaseProps = {
  testId?: string;
  size?: "large" | "medium" | "small" | "extraSmall";
  disabled?: boolean;
  variant?: "primary" | "secondary" | "tertiary" | "transparent" | "sand";
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  alignment?: "left" | "right" | "center";
  processing?: boolean;
};

type InnerProps = BaseProps & {
  minProcessing: boolean;
};

export type ButtonProps = BaseProps & ButtonHTMLAttributes<HTMLButtonElement>;
export type LinkButtonProps = BaseProps &
  AnchorHTMLAttributes<HTMLAnchorElement>;

const getBackgroundColor = (p: InnerProps): Color => {
  if (p.disabled && !p.minProcessing) {
    return "disabled";
  }

  switch (p.variant) {
    case "tertiary":
      return "white";
    case "secondary":
      return "petrol";
    case "primary":
      return "veloplusRot";
    case "transparent":
      return "transparent";
    case "sand":
      return "sand";
  }
};

const getHoverBackgroundColor = (p: InnerProps): Color => {
  switch (p.variant) {
    case "tertiary":
      return "white";
    case "secondary":
      return "petrolHover";
    case "transparent":
      return "petrolHover";
    case "primary":
      return "veloplusRotHover";
    case "sand":
      return "sand";
  }
};

const getBorder = (p: InnerProps): string => {
  if (p.variant === "tertiary" && !p.disabled) {
    return `1px solid ${colors.petrol}`;
  }
  return `1px solid ${colors[getBackgroundColor(p)]}`;
};

const getHoverBorder = (p: InnerProps): string => {
  if (p.variant === "tertiary" && !p.disabled) {
    return `1px solid ${colors.petrolHover}`;
  }
  return getBorder(p);
};

const getTextColor = (p: InnerProps): Color => {
  if (p.disabled) {
    return "white";
  }

  switch (p.variant) {
    case "tertiary":
      return "petrol";
    case "secondary":
      return "white";
    case "primary":
      return "white";
    case "transparent":
      return "white";
    case "sand":
      return "petrol";
  }
};

const getHoverTextColor = (p: InnerProps): Color => {
  switch (p.variant) {
    case "tertiary":
      return "petrolHover";
    case "sand":
      return "petrolHover";
    default:
      return getTextColor(p);
  }
};

const getFont = (p: InnerProps): Font => {
  switch (p.size) {
    case "large":
      return "button";
    case "medium":
      return "button";
    case "small":
      return "buttonSmall";
    case "extraSmall":
      return "buttonExtraSmall";
  }
};
const paddings = {
  extraSmall: css`
    padding: 0px;
    padding-left: 9px;
    padding-right: 9px;
  `,
  small: css`
    padding: 0px;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 7px;
    padding-bottom: 7px;
  `,
  medium: css`
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 7px;
    padding-bottom: 7px;
  `,
  large: css`
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 12px;
    padding-bottom: 12px;
  `,
};

const buttonStyle = css<InnerProps>`
  background-color: ${selectColor(getBackgroundColor)};
  cursor: not-allowed;
  ${iff((p) => !p.disabled)`
      cursor: pointer;
      &:hover {
        background-color: ${selectColor(getHoverBackgroundColor)};
        color: ${selectColor(getHoverTextColor)};
        border: ${(p) => getHoverBorder(p)};
      }
  `}
  ${(p) => paddings[p.size]};
  border-radius: ${selectSize("buttonBorderRadius")}px;
  display: inline-flex;
  border: ${getBorder};
  appearance: none;
  align-items: center;
  box-sizing: border-box;

  justify-content: ${(p) =>
    p.alignment === "left"
      ? "flex-start"
      : p.alignment === "right"
      ? "flex-end"
      : "center"};
  ${selectFont(getFont)};
  text-decoration: none;
  color: ${selectColor(getTextColor)};
  ${iff((p) => p.variant === "transparent")`
    text-shadow: 0 2px 4px rgba(0,0,0,0.5);
  `}

  &:focus {
    outline: none;
  }
`;

const StandardButton = styled.button.attrs((p: InnerProps) => ({
  disabled: p.disabled || p.minProcessing,
}))<InnerProps>`
  ${buttonStyle}
  opacity: ${(p) => (p.minProcessing ? 0.5 : 1)};
`;

const AnchorButton = styled.a<BaseProps>`
  ${buttonStyle}
`;

const iconPadding = 8;

const StartIconWrapper = styled.span`
  display: inline-flex;
  padding-right: ${iconPadding}px;
`;

const EndIconWrapper = styled.span`
  display: inline-flex;
  padding-left: ${iconPadding}px;
  margin-left: auto;
`;

function renderBody(
  children: ReactNode,
  startIcon?: ReactNode,
  endIcon?: ReactNode,
) {
  return (
    <>
      {startIcon ? <StartIconWrapper>{startIcon}</StartIconWrapper> : null}
      {children}
      {endIcon ? <EndIconWrapper>{endIcon}</EndIconWrapper> : null}
    </>
  );
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { children, startIcon, endIcon, processing, disabled, testId, ...rest },
    ref,
  ) => {
    const { minProcessing, setMinProcessing } = useMinProcessing();
    useEffect(
      () => setMinProcessing(processing),
      [processing, setMinProcessing],
    );

    return (
      <StandardButton
        data-testid={testId}
        ref={ref}
        disabled={disabled}
        minProcessing={minProcessing}
        {...rest}
      >
        {renderBody(children, startIcon, endIcon)}
        {minProcessing ? (
          <ProcessingIndicator
            style={{ marginLeft: 10 }}
            color="white"
            size={18}
          />
        ) : null}
      </StandardButton>
    );
  },
);

export const LinkButton: React.FC<LinkButtonProps> = React.forwardRef(
  ({ children, startIcon, endIcon, href, disabled, ...rest }, ref) => (
    <AnchorButton
      ref={ref as any}
      href={disabled ? undefined : href}
      disabled={disabled}
      {...rest}
      onClick={disabled ? (e) => e.preventDefault() : rest.onClick}
    >
      {renderBody(children, startIcon, endIcon)}
    </AnchorButton>
  ),
);

Button.defaultProps = {
  variant: "primary",
  size: "large",
  alignment: "center",
};

LinkButton.defaultProps = {
  variant: "primary",
  size: "large",
  alignment: "center",
};

export default Button;
