import { get, isFunction, isNumber } from "lodash-es";

import { css } from "styled-components";
import type {
  Color,
  Font,
  Size,
  ThemeGroup,
  ThemeKeyOrFunc,
  ThemeKeySelector,
  TransformFunc,
  ZIndex,
} from "../config/theme";

export const select =
  <T extends string>(
    group: ThemeGroup,
    themeKeyOrFunc: ThemeKeyOrFunc<T>,
    transform: TransformFunc = null,
  ) =>
  (props: any) => {
    let transformFunc = null;
    const theme = props.theme;

    if (transform) {
      if (isNumber(transform)) {
        transformFunc = (v: any) => (transform as number) * v;
      } else if (isFunction(transform)) {
        transformFunc = transform;
      } else {
        // tslint:disable-next-line:no-console
        console.warn("transform has to be function or number");
      }
    }

    const themeKey = isFunction(themeKeyOrFunc)
      ? (themeKeyOrFunc as ThemeKeySelector<T>)(props)
      : themeKeyOrFunc;

    const value = get(theme[group], themeKey, themeKey);
    if (transformFunc) {
      return transformFunc(value);
    }
    return value;
  };

export const selectColor = (
  themeKeyOrFunc: ThemeKeyOrFunc<Color>,
  transform?: TransformFunc,
) => select("colors", themeKeyOrFunc, transform);

export const selectCurrentPaletteColor = (key: "active" | "passive" | "text") =>
  selectColor("currentPalette", (palette) => palette[key]);

export const selectSize = (
  themeKeyOrFunc: ThemeKeyOrFunc<Size>,
  transform?: TransformFunc,
) => select("sizes", themeKeyOrFunc, transform);

export const selectZIndex = (
  themeKeyOrFunc: ThemeKeyOrFunc<ZIndex>,
  transform?: TransformFunc,
) => select("zIndexes", themeKeyOrFunc, transform);

export const selectGridWidth = (
  columns: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
) =>
  select(
    "sizes",
    "desktopGrid",
    (grid) => grid.col * columns + grid.gutter * (columns - 1),
  );
/*
export const selectBorder = (themeKeyOrFunc: ThemeKeyOrFunc<Borders>, transform?: TransformFunc) =>
  select("borders", themeKeyOrFunc, transform);
  */
export const selectFont = (
  themeKeyOrFunc: ThemeKeyOrFunc<Font>,
  transform?: TransformFunc,
) => select("fonts", themeKeyOrFunc, transform);

// experimental
export const iff =
  (condition) =>
  (first: any, ...vars: any[]) => {
    const ifResult = css(first, ...vars);
    const result = (props: any) => (condition(props) ? ifResult : undefined);
    result.else =
      (f: any, ...othervars: any[]) =>
      (props: any) =>
        condition(props) ? ifResult : css(f, ...othervars);
    return result;
  };

export const hideScrollbars = css`
  -webkit-overflow-scrolling: touch; /* force gravity scroll on touch devices*/

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
`;
