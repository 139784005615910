import { gql, useMutation, useQuery } from "@apollo/client";
import { useRouter } from "next/router";
import type { GetAdminMe } from "./types/GetAdminMe";
import useMe from "./useMe";
const QUERY = gql`
  query GetAdminMe {
    adminMe {
      _id
      primaryEmail {
        address
        verified
      }
    }
  }
`;
const STOP_MUTATION = gql`
  mutation StopImpersonation {
    stopImpersonating
  }
`;
export const useImpersonatingAdminMe = () => {
  const { me, refetch } = useMe();
  const router = useRouter();
  const { data } = useQuery<GetAdminMe>(QUERY, {
    ssr: false,
    fetchPolicy: "no-cache",
  });

  const [stopImpersonatingMutation, { client }] = useMutation(STOP_MUTATION);
  const stopImpersonating = async () => {
    await stopImpersonatingMutation();
    await refetch();
    await client.resetStore();
    router.push("/admin#/User");
  };
  if (!data?.adminMe || !me || data?.adminMe._id === me?._id) {
    return {
      impersonatedUser: me,
      adminMe: null,
      stopImpersonating,
    };
  }

  return {
    impersonatedUser: me,
    adminMe: data?.adminMe,
    stopImpersonating,
  };
};
