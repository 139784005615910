import React, { useState } from "react";
import { useImpersonatingAdminMe } from "../hooks/useImpersonatingAdminMe";
import Button from "../../layout/components/Button";
import styled from "styled-components";
const Base = styled.div`
  z-index: 100;
  position: fixed;
  bottom: 10px;
  left: 10px;
  padding: 6px 10px;
  opacity: 0.9;
  border: 2px solid red;
  background-color: white;
  line-height: 1.4;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  strong {
    font-weight: bold;
  }
  p {
    margin-bottom: 10px;
  }
`;
const ImpersonisationHeader = () => {
  const { adminMe, impersonatedUser, stopImpersonating } =
    useImpersonatingAdminMe();
  const [processing, setProcessing] = useState(false);
  if (adminMe) {
    return (
      <Base>
        <p>
          🥸 IMPERSONATING
          <br />
          <strong>{impersonatedUser?.primaryEmail?.address}</strong>
          <br />
          Kdnr: {impersonatedUser?.username}
        </p>
        <p>You: {adminMe?.primaryEmail?.address}</p>

        <Button
          processing={processing}
          size="extraSmall"
          onClick={async () => {
            try {
              setProcessing(true);
              await stopImpersonating();
            } finally {
              setProcessing(false);
            }
          }}
        >
          Stop impersonating
        </Button>
      </Base>
    );
  }
  return null;
};

export default React.memo(ImpersonisationHeader);
