import hexRgb from "hex-rgb";

import React, { useCallback } from "react";
import styled from "styled-components";
import type { Color } from "../../../config/theme";
import { hideScrollbars, iff, selectColor } from "../../../utils/themeUtils";
import BackIcon from "../../icons/components/BackIcon";
import NextIcon from "../../icons/components/NextIcon";
import useIsLeftRight from "../hooks/useIsLeftRight";

const Base = styled.div`
  height: 100%;
  position: relative;
  display: flex;

  max-width: 100%;
`;

const Scroller = styled.div`
  overflow: auto;
  ${hideScrollbars};
  height: 100%;
  width: 100%;
  display: flex;
`;

const Inner = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
`;

const HasMoreIndicator = styled.div<{
  color: Color;
  visible: boolean;
  right?: boolean;
}>`
  position: absolute;
  display: flex;
  align-items: center;
  width: 40px;
  top: 0px;
  bottom: 0px;
  transition: 0.3s;
  opacity: 0;
  pointer-events: none;
  ${iff((p) => p.visible)`
    pointer-events: all;
    opacity: 1;
  `}

  background: linear-gradient(
    ${(p) => (p.right ? "90deg" : "-90deg")},
    ${selectColor(
    (p) => p.color,
    (v) => hexRgb(v, { format: "css", alpha: 0 }),
  )}
      0%,
    ${selectColor((p) => p.color)} 50%
  );
`;

const HasMoreLeft: any = styled(HasMoreIndicator).attrs((props: any) => ({
  children: <BackIcon color={props.iconColor} />,
}))`
  left: 0px;
  justify-content: flex-start;
  & > * {
    margin-left: 10px;
  }
`;

const HasMoreRight: any = styled(HasMoreIndicator).attrs((props: any) => ({
  right: true,
  children: <NextIcon color={props.iconColor} />,
}))`
  right: 0px;
  justify-content: flex-end;
  & > * {
    margin-right: 10px;
  }
`;

export interface HorizontalScrollerProps {
  style?: React.CSSProperties;
  className?: string;
  fadeColor?: Color;
  iconColor?: Color;
}

const HorizontalScroller: React.FC<HorizontalScrollerProps> = ({
  style,
  className,
  children,
  fadeColor = "white",
  iconColor = "grey",
}) => {
  const { ref, isLeft, isRight, innerContentRef } = useIsLeftRight();
  // FIXME: scroll by more intelligent values
  const scrollLeft = useCallback(() => {
    if (ref.current) {
      ref.current.scrollBy({ left: -75, behavior: "smooth" });
    }
  }, [ref]);
  const scrollRight = useCallback(() => {
    if (ref.current) {
      ref.current.scrollBy({ left: 75, behavior: "smooth" });
    }
  }, [ref]);

  return (
    <Base style={style} className={className}>
      <Scroller ref={ref}>
        <Inner ref={innerContentRef}>{children}</Inner>
      </Scroller>
      <HasMoreLeft
        visible={!isLeft}
        color={fadeColor}
        iconColor={iconColor}
        onClick={scrollLeft}
      />
      <HasMoreRight
        visible={!isRight}
        color={fadeColor}
        iconColor={iconColor}
        onClick={scrollRight}
      />
    </Base>
  );
};

export default HorizontalScroller;
