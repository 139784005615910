import { useRouter } from "next/router";
import React from "react";
import useBreadcrumbPaths from "../../navigation/hooks/useBreadcrumbPaths";
import usePalette from "../../navigation/hooks/usePalette";
import PaletteThemeProvider from "./PaletteThemeProvider";

interface Props {
  path: string;
}
const RoutePaletteThemeProvider: React.FC<Props & any> = (props) => {
  const { asPath, query } = useRouter() ?? {};

  const breadcrumbPaths = useBreadcrumbPaths(
    (query?.menuPath as string) ?? asPath,
  );
  // we are interested in the top level
  const topLevelPage = breadcrumbPaths[0];

  const paletteKey = usePalette(topLevelPage?.path);

  return <PaletteThemeProvider paletteKey={paletteKey} {...props} />;
};

export default React.memo(RoutePaletteThemeProvider);
