import { times } from "lodash-es";
import React from "react";
import useKeyPress from "react-use/lib/useKeyPress";
import styled from "styled-components";
import { selectSize, selectZIndex } from "../../../utils/themeUtils";
import ContentColumn from "../../layout/components/ContentColumn";

const Base = styled.div`
  position: fixed;
  z-index: ${selectZIndex("gridLines")};
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  pointer-events: none;
`;
const Grid = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: repeat(
    ${selectSize("desktopGrid", (v) => v.numberOfColumns)},
    1fr
  );
  grid-column-gap: ${selectSize("desktopGrid", (v) => v.gutter)}px;
  & > * {
    background-color: rgba(128, 128, 128, 0.5);
    height: 100%;
  }
`;

export interface GridLinesProps {
  style?: React.CSSProperties;
  className?: string;
}

const GridLines: React.FC<GridLinesProps> = () => {
  const [isPressed] = useKeyPress((e) => e.key === "g" && e.ctrlKey);

  return isPressed ? (
    <Base>
      <ContentColumn style={{ height: "100%" }}>
        <Grid>
          {times(12).map((i) => (
            <div key={i} />
          ))}
        </Grid>
      </ContentColumn>
    </Base>
  ) : null;
};

export default GridLines;
