import gql from "graphql-tag";

export const PageBaseParts = gql`
  fragment PageBaseParts on Page {
    id
    path
    slug
    menuId
    palette
    childPages {
      id
    }
    pageTemplate
    navigationTitle
    processChildPaths
  }
`;
