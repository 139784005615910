import React from "react";
import styled, { css } from "styled-components";
import { selectSize } from "../../../utils/themeUtils";

export const contentColumnStyles = css`
  max-width: ${selectSize("maxContentWidth")}px;
  margin: 0 auto;
`;
const Base = styled.div`
  ${contentColumnStyles};
`;

export interface ContentColumnProps {
  style?: React.CSSProperties;
  className?: string;
}

const ContentColumn: React.FC<ContentColumnProps> = ({
  style,
  className,
  children,
}) => (
  <Base style={style} className={className}>
    {children}
  </Base>
);

export default ContentColumn;
