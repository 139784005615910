import type { Store } from "easy-peasy";
import { createStore } from "easy-peasy";

import rootModel from "./model";
import type { ApolloClient } from "@apollo/client";

export type InitialState = Partial<typeof rootModel>;

let store: Store<typeof rootModel, {}>;
export const getStore = () => store;

export function initializeStore({
  initialState = {},
  apollo,
  googleTagManager,
}: {
  initialState: InitialState;
  apollo: ApolloClient<object>;
  googleTagManager: GoogleTagManager;
}) {
  store = createStore<typeof rootModel, {}>(rootModel, {
    initialState,
    injections: {
      apollo,
      googleTagManager,
    },
  });
  return store;
}
