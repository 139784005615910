import { useEffect } from "react";
import useMe from "../../profile/hooks/useMe";

const SentryUserIdSetter = () => {
  const { me } = useMe();
  const userId = me?._id;

  useEffect(() => {
    import("../lib/sentry").then((e) => {
      const { configureScope } = e.default();
      if (userId) {
        configureScope((scope) => scope.setUser({ id: userId }));
      } else {
        configureScope((scope) => scope.setUser(null));
      }
    });
  }, [userId]);

  return null;
};

export default SentryUserIdSetter;
