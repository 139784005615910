import React from "react";
import styled, { css } from "styled-components";
import mediaQueries from "../../../utils/mediaQueries";
import { selectSize } from "../../../utils/themeUtils";

export const pageColumnStyles = css`
  max-width: ${selectSize("maxPageWidth")}px;
  margin: 0 auto;
  padding-left: ${selectSize("mobilePagePadding")}px;
  padding-right: ${selectSize("mobilePagePadding")}px;
  ${mediaQueries.desktop`
    padding-left: ${selectSize("desktopPagePadding")}px;
    padding-right: ${selectSize("desktopPagePadding")}px;
  `};
`;
const Base = styled.div`
  ${pageColumnStyles}
`;

export interface PageColumnProps {
  style?: React.CSSProperties;
  className?: string;
}

const PageColumn: React.FC<PageColumnProps> = ({
  style,
  className,
  children,
}) => (
  <Base style={style} className={className}>
    {children}
  </Base>
);

export default PageColumn;
