const zIndexes = {
  scrollableGalleryButton: 5,
  modalContainer: 11,
  imageGalleryFullscreenModal: 50,
  cartWidget: 100,
  mobileNavBarContainer: 100,
  megaDropDown: 900,
  modalOverlay: 999,
  modalContent: 1000,
  popupParentOverlay: 1000,
  gridLines: 10000,
  form: 3,
};

export default zIndexes;
