import gql from "graphql-tag";

export const ProductReviewParts = gql`
  fragment ProductReviewParts on ShopProductReview {
    created
    _id
    rating
    title
    review
    response {
      text
    }
    product {
      _id
    }
  }
`;

export const ProductReviewPartsWithAuthorParts = gql`
  fragment ProductReviewPartsWithAuthorParts on ShopProductReview {
    ...ProductReviewParts
    author @include(if: $includePrivateAuthorInformation) {
      _id
      username
      primaryEmail {
        address
        verified
      }
    }
  }
  ${ProductReviewParts}
`;
export const GetReviews = gql`
  query GetProductReviews(
    $productId: ID!
    $includePrivateAuthorInformation: Boolean = false
  ) {
    product(productId: $productId) {
      _id

      # see https://github.com/unchainedshop/unchained/issues/444
      ... on ShopConfigurableProduct {
        reviews(limit: 50, sort: { key: "created", value: DESC }) {
          ...ProductReviewPartsWithAuthorParts
        }
      }
      ... on ShopSimpleProduct {
        reviews(limit: 50, sort: { key: "created", value: DESC }) {
          ...ProductReviewPartsWithAuthorParts
        }
      }
    }
  }
  ${ProductReviewPartsWithAuthorParts}
`;
