import type { EditorProps } from "@react-page/editor";

export const BRAND_ASSORTMENT_ID = "__BRANDS__";
export const CONNECTION_PAGE_SIZE = 10;
export const LOG_CODE_LENGTH = 6;
export const POPUP_FLASH_DURATION_MS = 3000;

export const CONTENT_EDITOR_CELL_SPACING: EditorProps["cellSpacing"] = {
  x: 30,
  y: 0,
};

/*
  IMPORTANT: when making changes to the mime types, don't forget to also update the corresponding
  server-side mime types in the veloplus-shop-api project
 */
export const MIME_TYPES = {
  images: [
    "image/bmp",
    "image/jpeg",
    "image/png",
    "image/tiff",
    "image/svg+xml",
  ],
  pdf: ["application/pdf", "application/x-pdf"],
};
