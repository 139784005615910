import type { Thunk } from "easy-peasy";
import { thunk } from "easy-peasy";
import type { Injections, AppModel } from "../../../lib/types";
import type {
  ShopAddressInput,
  ShopContactInput,
  ShopUserProfileInput,
} from "../../../types/global-types";
import type { ProfileFormModel } from "../components/ProfileForm";
import type { UseProfileAndOrder_me } from "../hooks/types/UseProfileAndOrder";
import updateContactAndBillingAddress from "../queries/updateContactAndBillingAddress";

export interface CheckoutState {}

/* 📌 ACTION-CREATORS */

const initialState = {
  /* 📌 INITIAL-STATE */
};

export interface CheckoutModel extends CheckoutState {
  updateContactAndProfile: Thunk<
    CheckoutModel,
    ProfileFormModel,
    Injections,
    AppModel
  >;
  updateDeliveryAddress: Thunk<
    CheckoutModel,
    ShopAddressInput,
    Injections,
    AppModel
  >;

  signupAsGuest: Thunk<
    CheckoutModel,
    {
      me?: UseProfileAndOrder_me;
      birthday?: Date;
      contact: ShopContactInput;
      billingAddress: ShopAddressInput;
    },
    Injections,
    AppModel
  >;
}

const model: CheckoutModel = {
  ...initialState,
  /* 📌 ACTION-REDUCERS */
  updateContactAndProfile: thunk(
    async (
      _,
      { emailAddress, address, ...rest },
      { injections: { apollo } },
    ) => {
      return await updateContactAndBillingAddress(apollo, {
        profile: {
          ...rest,
          address,
        },
        billingAddress: address,
        contact: {
          emailAddress: emailAddress,
          telNumber: rest.phoneMobile ?? rest.phoneOffice ?? rest.phonePrivate,
        },
      });
    },
  ),
  updateDeliveryAddress: thunk(
    async (_, deliveryAddress, { injections: { apollo } }) => {
      await updateContactAndBillingAddress(apollo, {
        deliveryAddress: deliveryAddress ? deliveryAddress : false,
      });
    },
  ),
  signupAsGuest: thunk(
    async (
      _,
      { me, contact, billingAddress, birthday },
      { dispatch: { profile }, injections: { apollo } },
    ) => {
      if (!me || !me.isGuest) {
        await profile.user.loginAsGuest();
      }
      await updateContactAndBillingAddress(apollo, {
        contact,
        billingAddress,
        profile: { birthday },
      });
    },
  ),
};

export default model;
