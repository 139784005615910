/* 📌 IMPORTS */
import user from "./user";
import bookmarks from "./bookmarks";
import visitProduct from "./visitProduct";
import preferences from "./preferences";

const reducers = {
  /* 📌 SYMBOLS */
  user,
  bookmarks,
  visitProduct,
  preferences,
};

export default reducers;
