import type { Thunk } from "easy-peasy";
import { thunk } from "easy-peasy";
import gql from "graphql-tag";

import type { ShopVPSShippingMethod } from "../../../types/global-types";
import {
  DeliveryParts,
  SupportedDeliveryProvidersParts,
} from "../../cart/queries";
import { ShopAddressParts } from "../../shop/queries";
import type {
  SetOrderDeliveryProvider,
  SetOrderDeliveryProviderVariables,
} from "./types/SetOrderDeliveryProvider";
import type {
  UpdateOrderDeliveryPickUp,
  UpdateOrderDeliveryPickUpVariables,
} from "./types/UpdateOrderDeliveryPickUp";
import type {
  UpdateOrderDeliveryShipping,
  UpdateOrderDeliveryShippingVariables,
} from "./types/UpdateOrderDeliveryShipping";
import type {
  UpdateIsBulkOrder,
  UpdateIsBulkOrderVariables,
} from "./types/UpdateIsBulkOrder";
import type {
  SetPartialDelivery,
  SetPartialDeliveryVariables,
} from "./types/SetPartialDelivery";
import type { DeliveryParts_delivery_ShopOrderDeliveryPickUp_pickUpLocations_address } from "../../cart/queries/types/DeliveryParts";
import type { AppModel, Injections } from "../../../lib/types";

export interface CheckoutState {}

/* 📌 ACTION-CREATORS */

const initialState = {
  /* 📌 INITIAL-STATE */
};

export interface CheckoutModel extends CheckoutState {
  updateOrderDeliveryProvider: Thunk<
    CheckoutModel,
    { orderId: string; deliveryProviderId: string },
    Injections,
    AppModel
  >;
  updateOrderDeliveryShipping: Thunk<
    CheckoutModel,
    { orderDeliveryId: string; activeShippingMethod: ShopVPSShippingMethod },
    Injections,
    AppModel
  >;
  updateOrderDeliveryPickUp: Thunk<
    CheckoutModel,
    {
      orderDeliveryId: string;
      orderPickUpLocation: {
        __typename: "ShopOrderPickUpLocation";
        _id: string;
        name: string;
        address: DeliveryParts_delivery_ShopOrderDeliveryPickUp_pickUpLocations_address | null;
      };
    },
    Injections,
    AppModel
  >;
  updateIsBulkOrder: Thunk<
    CheckoutModel,
    { orderDeliveryId: string; isBulkOrder: boolean },
    Injections,
    AppModel
  >;
  setPartialDelivery: Thunk<
    CheckoutModel,
    { token: string },
    Injections,
    AppModel
  >;
}

const SetOrderDeliveryProviderMutation = gql`
  mutation SetOrderDeliveryProvider($orderId: ID!, $deliveryProviderId: ID!) {
    setOrderDeliveryProvider(
      orderId: $orderId
      deliveryProviderId: $deliveryProviderId
    ) {
      _id
      ...DeliveryParts
      ...SupportedDeliveryProvidersParts
    }
  }
  ${DeliveryParts}
  ${SupportedDeliveryProvidersParts}
`;

const UpdateOrderDeliveryShippingMutation = gql`
  mutation UpdateOrderDeliveryShipping(
    $orderDeliveryId: ID!
    $meta: ShopJSON!
  ) {
    updateOrderDeliveryShipping(
      orderDeliveryId: $orderDeliveryId
      meta: $meta
    ) {
      _id
      vpsActiveShippingMethod
    }
  }
`;

const UpdateOrderDeliveryPickUpMutation = gql`
  mutation UpdateOrderDeliveryPickUp(
    $orderDeliveryId: ID!
    $orderPickUpLocationId: ID!
  ) {
    updateOrderDeliveryPickUp(
      orderDeliveryId: $orderDeliveryId
      orderPickUpLocationId: $orderPickUpLocationId
    ) {
      _id
      activePickUpLocation {
        _id
        name
        address {
          ...ShopAddressParts
        }
      }
    }
  }
  ${ShopAddressParts}
`;

const UpdateIsBulkOrderMutation = gql`
  mutation UpdateIsBulkOrder($partialDeliveryEnabled: Boolean!) {
    vpsSetPartialDeliveryOnCart(enabled: $partialDeliveryEnabled) {
      _id
      ... on ShopOrderDeliveryShipping {
        vpsIsBulkOrder
      }
    }
  }
`;

const SetPartialDeliveryMutation = gql`
  mutation SetPartialDelivery($token: String!) {
    vpsSetPartialDelivery(token: $token)
  }
`;

const model: CheckoutModel = {
  ...initialState,
  /* 📌 ACTION-REDUCERS */
  updateOrderDeliveryProvider: thunk(
    async (_, variables, { injections: { apollo } }) => {
      await apollo.mutate<
        SetOrderDeliveryProvider,
        SetOrderDeliveryProviderVariables
      >({
        mutation: SetOrderDeliveryProviderMutation,
        variables,
      });
    },
  ),
  updateOrderDeliveryShipping: thunk(
    async (
      _,
      { orderDeliveryId, activeShippingMethod },
      { injections: { apollo } },
    ) => {
      await apollo.mutate<
        UpdateOrderDeliveryShipping,
        UpdateOrderDeliveryShippingVariables
      >({
        mutation: UpdateOrderDeliveryShippingMutation,
        variables: {
          orderDeliveryId,
          meta: { activeShippingMethod },
        },
        optimisticResponse: {
          updateOrderDeliveryShipping: {
            __typename: "ShopOrderDeliveryShipping",
            _id: orderDeliveryId,
            vpsActiveShippingMethod: activeShippingMethod,
          },
        },
      });
    },
  ),
  updateOrderDeliveryPickUp: thunk(
    async (
      _,
      { orderDeliveryId, orderPickUpLocation },
      { injections: { apollo } },
    ) => {
      await apollo.mutate<
        UpdateOrderDeliveryPickUp,
        UpdateOrderDeliveryPickUpVariables
      >({
        mutation: UpdateOrderDeliveryPickUpMutation,
        variables: {
          orderDeliveryId,
          orderPickUpLocationId: orderPickUpLocation._id,
        },
        optimisticResponse: {
          updateOrderDeliveryPickUp: {
            __typename: "ShopOrderDeliveryPickUp",
            _id: orderDeliveryId,
            activePickUpLocation: {
              ...orderPickUpLocation,
            },
          },
        },
      });
    },
  ),
  updateIsBulkOrder: thunk(
    async (_, { orderDeliveryId, isBulkOrder }, { injections: { apollo } }) => {
      await apollo.mutate<UpdateIsBulkOrder, UpdateIsBulkOrderVariables>({
        mutation: UpdateIsBulkOrderMutation,
        variables: {
          partialDeliveryEnabled: !isBulkOrder,
        },
        optimisticResponse: {
          vpsSetPartialDeliveryOnCart: {
            __typename: "ShopOrderDeliveryShipping",
            _id: orderDeliveryId,
            vpsIsBulkOrder: isBulkOrder,
          },
        },
      });
    },
  ),
  setPartialDelivery: thunk(
    async (_, { token }, { injections: { apollo } }) => {
      const { data } = await apollo.mutate<
        SetPartialDelivery,
        SetPartialDeliveryVariables
      >({
        mutation: SetPartialDeliveryMutation,
        variables: {
          token,
        },
      });

      if (data?.vpsSetPartialDelivery === false) {
        throw new Error("setting partial delivery failed");
      }
    },
  ),
};

export default model;
