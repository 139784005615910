import dynamic from "next/dynamic";
import React from "react";
import { useStoreActions, useStoreState } from "../../../lib/model";

const ScannerDialog = dynamic({
  loader: () => import("./ScannerDialog"),
  ssr: false,
});

export type GlobalScannerProps = {};

const GlobalScanner: React.FC<GlobalScannerProps> = () => {
  const setShowScanner = useStoreActions((s) => s.navigation.setScannerVisible);

  const showScanner = useStoreState((s) => s.navigation.scannerVisible);

  return (
    <ScannerDialog isOpen={showScanner} close={() => setShowScanner(false)} />
  );
};

export default React.memo(GlobalScanner);
