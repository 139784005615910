export const palettes = {
  shop: {
    passive: "#B11219",
    active: "#C31924",
    text: "#ffffff",
  },
  service: {
    passive: "#CC7B00",
    active: "#E38900",
    text: "#ffffff",
  },
  competence: {
    passive: "#1E7BBA",
    active: "#228BD1",
    text: "#ffffff",
  },
  experience: {
    passive: "#3F7583",
    active: "#478392",
    text: "#ffffff",
  },
  about: {
    passive: "#DAD8D0",
    active: "#EDEBE2",
    text: "#3F7583",
  },
};
const colors = {
  black: "#000000",
  petrol: "#478392",
  petrolHover: "#116074",
  veloplusRot: "#C31924",
  veloplusRotHover: "#88161E",
  redLight: "#f8d7da",
  sand: "#EDEBE2",
  infoBox: "#F6F6F6",
  disabled: "#EAEAEA",
  darkDisabled: "#6A6A6A",
  dark: "#1C1C1C",
  grey: "#393939",
  greyLight: "#CCCCCC",
  textBlack: "#000000",
  textWhite: "#FFFFFF",
  white: "#FFFFFF",
  almostWhite: "#F4F4F4",
  textGrey: "#808080",
  greyLine: "#979797",
  green: "#05A72E",
  greenLight: "#d4edda",
  productHover: "rgba(108,155,167,0.1)",
  delayed: "#FFA500",

  delayedLight: "#ffe2cd",

  tip: "#04A7D4",
  searchFieldBackground: "#EAEAEA",
  searchFieldText: "#262626",

  skyBlue: "#ECF2F4",
  skyBlueDark: "#D2DFE4",

  budget: "#0177BD",
  highlight: "#DEB107",
  new: "#A50081",
  blueLight: "#ECF2F4",
  blueDark: "#228AD0",
  transparent: "transparent",
  currentPalette: palettes.shop,
  paletteKey: "shop",
};

export default colors;
