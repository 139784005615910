import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useEffect } from "react";
import { useStoreActions } from "../../../lib/model";
import {
  DeliveryParts,
  PaymentParts,
  SupportedPaymentProvidersParts,
} from "../../cart/queries";
import type { UsePayment, UsePaymentVariables } from "./types/UsePayment";

export const USE_PAYMENT = gql`
  query UsePayment($orderId: ID!) {
    order(orderId: $orderId) {
      _id
      vpsCanPayByInvoice
      ...DeliveryParts
      ...PaymentParts
      ...SupportedPaymentProvidersParts
    }
  }

  ${DeliveryParts}
  ${PaymentParts}
  ${SupportedPaymentProvidersParts}
`;

const usePayment = (orderId: string) => {
  const { data, previousData, error, loading } = useQuery<
    UsePayment,
    UsePaymentVariables
  >(USE_PAYMENT, {
    variables: { orderId },
    fetchPolicy: "cache-and-network",
    ssr: false,
  });
  const setPaymentProvider = useStoreActions(
    (s) => s.checkout.payment.updateOrderPaymentProvider,
  );
  const order = (data ?? previousData)?.order;
  const paymentProviders = order?.supportedPaymentProviders;

  // see https://git.panter.ch/manul/vps/veloplus-shop/-/issues/958#note_276633
  const paymentProviderIsValid =
    order?.supportedPaymentProviders?.some(
      (p) => p._id === order?.payment?.provider?._id,
    ) ?? false;
  useEffect(() => {
    if (paymentProviders?.length > 0 && !paymentProviderIsValid) {
      setPaymentProvider({
        orderId,
        paymentProviderId: paymentProviders[0]._id,
      });
    }
  }, [orderId, setPaymentProvider, paymentProviders, paymentProviderIsValid]);

  return {
    data:
      order && order?._id === orderId
        ? {
            activePayment: order?.payment,
            activeDelivery: order?.delivery,
            paymentProviders,
            paymentProviderIsValid,
            canPayByInvoice: order?.vpsCanPayByInvoice,
          }
        : {},
    error,
    loading,
  };
};

export default usePayment;
