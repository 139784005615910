import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  DeliveryParts,
  SupportedDeliveryProvidersParts,
} from "../../cart/queries";
import { BulkOrderParts } from "../../order/queries";
import type { UseDelivery, UseDeliveryVariables } from "./types/UseDelivery";

export const USE_DELIVERY = gql`
  query UseDelivery($orderId: ID!) {
    order(orderId: $orderId) {
      _id
      ...DeliveryParts
      ...BulkOrderParts
      ...SupportedDeliveryProvidersParts
    }
  }
  ${DeliveryParts}
  ${BulkOrderParts}
  ${SupportedDeliveryProvidersParts}
`;

const useDelivery = (orderId: string) => {
  const { data, previousData, error, loading } = useQuery<
    UseDelivery,
    UseDeliveryVariables
  >(USE_DELIVERY, {
    variables: { orderId },
    fetchPolicy: "cache-and-network",
    ssr: false,
  });
  const order = (data ?? previousData)?.order;
  const deliveryProviders = order?.supportedDeliveryProviders;

  return {
    data: {
      items: order?.items,
      cartId: order?._id,

      activeDelivery: order?.delivery,
      deliveryProviders,
      vpsFullDeliveryBlockingItems: order?.vpsFullDeliveryBlockingItems,
    },
    error,
    loading,
  };
};

export default useDelivery;
