import { useRouter } from "next/router";
import React, { useEffect } from "react";
import styled from "styled-components";
import type { Font } from "../../../config/theme";
import { useStoreActions, useStoreState } from "../../../lib/model";
import mediaQueries from "../../../utils/mediaQueries";
import { selectColor, selectFont, selectSize } from "../../../utils/themeUtils";
import useBaseConfig from "../../core/hooks/useBaseConfig";
import CloseIcon from "../../icons/components/CloseIcon";
import GroupIcon from "../../icons/components/GroupIcon";
import HomeIcon from "../../icons/components/HomeIcon";
import DeliveryIcon from "../../icons/components/DeliveryIcon";
import HorizontalScroller from "../../layout/components/HorizontalScroller";
import PageColumn from "../../layout/components/PageColumn";

const Base = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? "block" : "none")};
  background-color: ${selectColor("sand")};
  height: ${selectSize("headerBenefitsHeight")}px;
  position: relative;
  z-index: 2;
`;

const Content = styled(PageColumn)`
  display: flex;
  flex: 1;
`;

const ContextNavBase = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  & > * {
    margin-right: 25px;
  }

  ${mediaQueries.desktop`
    justify-content: space-evenly;
    & > * {
      margin-right: 0x;
    }
  `};
`;

const CloseButton = styled.button<{ onClick: any }>`
  border: none;
  appearance: none;
  background: none;
  cursor: pointer;
`;

const Item = styled.div<{ font?: Font }>`
  ${selectFont((p) => p.font || "contextNavigation")};
  color: ${selectColor("veloplusRot")};
  text-decoration: none;
  white-space: nowrap;
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 8px;
  }
`;

export interface HeaderBenefitsProps {}

interface InternalNavItem {
  font?: Font;
  title: string;
  icon?: object;
}

const InternalNavItem = ({ title, font, icon }: InternalNavItem) => (
  <Item font={font}>
    {icon}
    {title}
  </Item>
);

const HeaderBenefits: React.FC<HeaderBenefitsProps> = () => {
  const headerBenefits = useBaseConfig()?.data?.config?.headerBenefits;

  const benefitIcons = [
    <DeliveryIcon key="benefit-icon-1" />,
    <HomeIcon key="benefit-icon-2" />,
    <GroupIcon key="benefit-icon-3" />,
  ];

  const benefits = benefitIcons.reduce((result, icon, index) => {
    const benefitKey = `benefit${index + 1}`;
    const title = headerBenefits?.[benefitKey]?.trim();
    if (title) {
      result.push({
        title,
        icon,
      });
    }

    return result;
  }, [] as InternalNavItem[]);

  const increaseBenefitsNavCounter = useStoreActions(
    (s) => s.navigation.increaseBenefitsNavCounter,
  );
  const closeNav = useStoreActions((s) => s.navigation.closeBenefitsNav);
  const benefitsNavVisible = useStoreState(
    (s) => s.navigation.benefitsNavVisible,
  );
  const { asPath } = useRouter() ?? {};
  useEffect(() => {
    increaseBenefitsNavCounter(null);
  }, [increaseBenefitsNavCounter, asPath]);

  return (
    <Base visible={benefitsNavVisible}>
      <HorizontalScroller fadeColor="sand" iconColor="veloplusRot">
        <Content>
          <ContextNavBase>
            {benefits.map((item, index) => (
              <InternalNavItem key={index} {...item} />
            ))}
          </ContextNavBase>
          <CloseButton onClick={closeNav}>
            <CloseIcon color={"black"} style={{ height: "12px" }} />
          </CloseButton>
        </Content>
      </HorizontalScroller>
    </Base>
  );
};

export default React.memo(HeaderBenefits);
