import type { Thunk } from "easy-peasy";
import { thunk } from "easy-peasy";
import gql from "graphql-tag";
import type { AppModel, Injections } from "../../../lib/types";
import type {
  GetImpressionProductInfo,
  GetImpressionProductInfoVariables,
  GetImpressionProductInfo_base,
  GetImpressionProductInfo_base_ShopConfigurableProduct,
} from "./types/GetImpressionProductInfo";

const QUERY = gql`
  query GetImpressionProductInfo($productId: ID!, $fullProductId: ID!) {
    base: product(productId: $productId) {
      _id
      ... on ShopConfigurableProduct {
        vpsPrimaryAssortmentPath {
          links {
            assortmentTexts {
              title
            }
          }
        }
      }
    }

    variant: product(productId: $fullProductId) {
      _id
      ... on ShopSimpleProduct {
        vpsLegacyArticleNumber
      }
      texts {
        ... on ShopProductTexts {
          _id
          title
          brand
        }
      }
    }
  }
`;

export interface ProductState {}

/* 📌 ACTION-CREATORS */

const initialState = {
  /* 📌 INITIAL-STATE */
};

export interface ProductModel extends ProductState {
  pushProductImpressionToGoogleAnalytics: Thunk<
    ProductModel,
    { productId: string; fullProductId: string },
    Injections,
    AppModel
  >;
}

const isConfigurableProduct = (
  product: GetImpressionProductInfo_base,
): product is GetImpressionProductInfo_base_ShopConfigurableProduct =>
  product?.__typename === "ShopConfigurableProduct";

const model: ProductModel = {
  ...initialState,
  pushProductImpressionToGoogleAnalytics: thunk(
    async (_, variables, { injections: { apollo, googleTagManager } }) => {
      try {
        const { data } = await apollo.query<
          GetImpressionProductInfo,
          GetImpressionProductInfoVariables
        >({
          query: QUERY,
          variables,
        });

        if (data?.variant?.texts?._id) {
          const { title, brand } = data.variant.texts;
          const category =
            (isConfigurableProduct(data?.base)
              ? data?.base?.vpsPrimaryAssortmentPath?.links?.[1]
                  ?.assortmentTexts?.title
              : null) ?? "unknown";
          const isVariant = variables.fullProductId !== variables.productId;

          const event = isVariant ? "productVariantView" : "productGroupView";
          // old analytics
          const productItem = {
            name: title,
            id:
              data.variant?.__typename === "ShopSimpleProduct"
                ? data.variant.vpsLegacyArticleNumber ?? variables.fullProductId
                : variables.fullProductId,
            brand: brand,
            category,
          };
          const productImpression = {
            event,
            ecommerce: {
              detail: {
                products: [productItem],
              },
            },
          };

          googleTagManager.push(productImpression);
          // new v4 beta analytics, see https://git.panter.ch/manul/vps/veloplus-shop/-/issues/908
          googleTagManager.push({
            event: "view_item",
            ecommerce: {
              items: [
                {
                  item_name: productItem.name,
                  item_id: productItem.id,
                  item_brand: productItem.brand,
                  item_category: productItem.category,
                  quantity: "1", // immer 1
                },
              ],
            },
          });
        } else {
          console.warn(
            "data could not be loaded, product impression tracking failed",
            {
              variables,
              data,
            },
          );
        }
      } catch (err) {
        console.error("product impression tracking failed", err);
      }
    },
  ),
};

export default model;
