import React from "react";

export interface DeliveryIconProps {
  style?: React.CSSProperties;
  className?: string;
}

const DeliveryIcon: React.FC<DeliveryIconProps> = ({ style, className }) => (
  <svg
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 24 24"
    style={style}
    className={className}
    width="24"
    height="24"
  >
    <path d="M5 11v1h8V5H3V4c0-.6.4-1 1-1h10c.6 0 1 .4 1 1v2h4.7c1 0 1.6.6 1.9 1.1l2.1 3.4c.2.3.3.7.3 1V16c0 1-.7 2-2 2h-1a3 3 0 0 1-6 0h-4a3 3 0 0 1-6 0H4a1 1 0 0 1-1-1v-6H1V9h7v2H5zm3 5.8a1.2 1.2 0 1 1 0 2.4 1.2 1.2 0 0 1 0-2.4zm10 0a1.2 1.2 0 1 1 0 2.4 1.2 1.2 0 0 1 0-2.4zM15 14H5v2h.8a3 3 0 0 1 4.4 0h5.6a3 3 0 0 1 4.4 0H22v-4.6l-1.7-2.9a1 1 0 0 0-.9-.5H15v6zm1-5v3h5l-1.4-2.5a1 1 0 0 0-.9-.5H16zM0 6h8v2H0V6z" />
  </svg>
);

export default DeliveryIcon;
