import { useRouter } from "next/router";
import { useEffect } from "react";
import Seo from "../../content/components/Seo";
import useBaseConfig from "../hooks/useBaseConfig";

const SeoBaseHead = () => {
  const router = useRouter();

  const htmlTitleTemplate = useBaseConfig()?.data?.config?.htmlTitleTemplate;

  useEffect(() => {
    const hasForce = window?.location?.search?.includes("force=");
    if (hasForce) {
      window.history.replaceState(
        null,
        null,
        // remove force param
        location.pathname +
          location.search.replace(/[?&]force=[^&]+/, "").replace(/^&/, "?"),
      );
    }
    const hasMenuPath = window?.location?.search?.includes("menuPath=");
    if (hasMenuPath) {
      window.history.replaceState(
        null,
        null,
        // remove force param
        location.pathname +
          location.search.replace(/[?&]menuPath=[^&]+/, "").replace(/^&/, "?"),
      );
    }
  }, []);
  const isLandingPage = router.asPath === "/";
  return (
    <Seo
      titleTemplate={
        isLandingPage || !htmlTitleTemplate ? null : htmlTitleTemplate
      }
    />
  );
};

export default SeoBaseHead;
