import NextI18Next from "next-i18next";

const path = require("path");
const i18n = new NextI18Next({
  defaultLanguage: "de",
  otherLanguages: ["de"],
  defaultNS: "common",

  localePath: process.browser ? "/locales" : path.resolve("locales"),
});

export default i18n;

const {
  useTranslation: useTranslationOrg,
  appWithTranslation,
  Trans: TransOrg,
} = i18n;

export { useTranslationOrg, appWithTranslation, TransOrg };
