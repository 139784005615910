/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ConfigAssortmentRangeMode {
  ALWAYS_EXTENDED = "ALWAYS_EXTENDED",
  ALWAYS_NORMAL = "ALWAYS_NORMAL",
  USER_SELECT = "USER_SELECT",
}

export enum FormType {
  REPAIR_KIT_ORDER = "REPAIR_KIT_ORDER",
  SPONSORING_REQUEST = "SPONSORING_REQUEST",
}

export enum MenuId {
  FOOTER = "FOOTER",
  MAIN = "MAIN",
  META = "META",
  NONE = "NONE",
}

export enum PageTemplate {
  CONTENT = "CONTENT",
  JOBS = "JOBS",
  LANDING = "LANDING",
  ROOT = "ROOT",
  STORES = "STORES",
  TIPS = "TIPS",
  TRAVEL_REPORTS = "TRAVEL_REPORTS",
}

export enum ReviewsVersion {
  LEGACY = "LEGACY",
  STAMPED = "STAMPED",
}

export enum ShopDeliveryProviderType {
  PICKUP = "PICKUP",
  SHIPPING = "SHIPPING",
}

export enum ShopFilterType {
  MULTI_CHOICE = "MULTI_CHOICE",
  RANGE = "RANGE",
  SINGLE_CHOICE = "SINGLE_CHOICE",
  SWITCH = "SWITCH",
}

export enum ShopOrderDiscountTrigger {
  SYSTEM = "SYSTEM",
  USER = "USER",
}

export enum ShopPaymentProviderType {
  CARD = "CARD",
  GENERIC = "GENERIC",
  INVOICE = "INVOICE",
}

export enum ShopProductStatus {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  DRAFT = "DRAFT",
}

export enum ShopRoleAction {
  answerQuotation = "answerQuotation",
  authTwoFactor = "authTwoFactor",
  bookmarkProduct = "bookmarkProduct",
  bulkImport = "bulkImport",
  checkoutCart = "checkoutCart",
  createCart = "createCart",
  createEnrollment = "createEnrollment",
  impersonate = "impersonate",
  manageAssortments = "manageAssortments",
  manageBookmarks = "manageBookmarks",
  manageContent = "manageContent",
  manageCountries = "manageCountries",
  manageCurrencies = "manageCurrencies",
  manageCustomConfig = "manageCustomConfig",
  manageDeliveryProviders = "manageDeliveryProviders",
  manageFilters = "manageFilters",
  manageLanguages = "manageLanguages",
  managePaymentCredentials = "managePaymentCredentials",
  managePaymentProviders = "managePaymentProviders",
  manageProductReviews = "manageProductReviews",
  manageProducts = "manageProducts",
  manageQuotations = "manageQuotations",
  manageTwoFactor = "manageTwoFactor",
  manageUsers = "manageUsers",
  manageWarehousingProviders = "manageWarehousingProviders",
  manageWorker = "manageWorker",
  markOrderConfirmed = "markOrderConfirmed",
  markOrderDelivered = "markOrderDelivered",
  markOrderPaid = "markOrderPaid",
  markOrderRejected = "markOrderRejected",
  registerPaymentCredentials = "registerPaymentCredentials",
  requestQuotation = "requestQuotation",
  reviewProduct = "reviewProduct",
  search = "search",
  sendEmail = "sendEmail",
  updateCart = "updateCart",
  updateEnrollment = "updateEnrollment",
  updateOrder = "updateOrder",
  updateOrderDelivery = "updateOrderDelivery",
  updateOrderDiscount = "updateOrderDiscount",
  updateOrderItem = "updateOrderItem",
  updateOrderPayment = "updateOrderPayment",
  updateProductReview = "updateProductReview",
  updateUser = "updateUser",
  updateUsername = "updateUsername",
  viewAssortment = "viewAssortment",
  viewAssortments = "viewAssortments",
  viewCountries = "viewCountries",
  viewCountry = "viewCountry",
  viewCurrencies = "viewCurrencies",
  viewCurrency = "viewCurrency",
  viewDeliveryInterfaces = "viewDeliveryInterfaces",
  viewDeliveryProvider = "viewDeliveryProvider",
  viewDeliveryProviders = "viewDeliveryProviders",
  viewEnrollment = "viewEnrollment",
  viewEnrollments = "viewEnrollments",
  viewEvent = "viewEvent",
  viewEvents = "viewEvents",
  viewFilter = "viewFilter",
  viewFilters = "viewFilters",
  viewInternalProductInfos = "viewInternalProductInfos",
  viewLanguage = "viewLanguage",
  viewLanguages = "viewLanguages",
  viewLogs = "viewLogs",
  viewOrder = "viewOrder",
  viewOrders = "viewOrders",
  viewPaymentInterfaces = "viewPaymentInterfaces",
  viewPaymentProvider = "viewPaymentProvider",
  viewPaymentProviders = "viewPaymentProviders",
  viewProduct = "viewProduct",
  viewProducts = "viewProducts",
  viewQuotation = "viewQuotation",
  viewQuotations = "viewQuotations",
  viewShopInfo = "viewShopInfo",
  viewTranslations = "viewTranslations",
  viewUser = "viewUser",
  viewUserEnrollments = "viewUserEnrollments",
  viewUserOrders = "viewUserOrders",
  viewUserPrivateInfos = "viewUserPrivateInfos",
  viewUserPublicInfos = "viewUserPublicInfos",
  viewUserQuotations = "viewUserQuotations",
  viewUserRoles = "viewUserRoles",
  viewUsers = "viewUsers",
  viewWarehousingInterfaces = "viewWarehousingInterfaces",
  viewWarehousingProvider = "viewWarehousingProvider",
  viewWarehousingProviders = "viewWarehousingProviders",
  voteProductReview = "voteProductReview",
  vpsCrifChecks = "vpsCrifChecks",
  vpsDeleteAllCrifForUser = "vpsDeleteAllCrifForUser",
  vpsImpersonate = "vpsImpersonate",
  vpsSubmitOrderReturn = "vpsSubmitOrderReturn",
  vpsUpdateDiasProfileFeature = "vpsUpdateDiasProfileFeature",
  vpsViewAllPurchaser = "vpsViewAllPurchaser",
  vpsViewAllReviews = "vpsViewAllReviews",
  vpsViewDiasProfile = "vpsViewDiasProfile",
}

export enum ShopSearchOrderBy {
  default = "default",
  meta_publicationDate_DESC = "meta_publicationDate_DESC",
  meta_resolvedDiscountedCatalogPriceAmount_ASC = "meta_resolvedDiscountedCatalogPriceAmount_ASC",
  meta_resolvedDiscountedCatalogPriceAmount_DESC = "meta_resolvedDiscountedCatalogPriceAmount_DESC",
}

export enum ShopVPSAvailability {
  AVAILABLE = "AVAILABLE",
  DELAYED_AVAILABILITY = "DELAYED_AVAILABILITY",
  IMMEDIATE = "IMMEDIATE",
  OUT_OF_STOCK = "OUT_OF_STOCK",
  SOLD = "SOLD",
  UNKNOWN_AVAILABILITY = "UNKNOWN_AVAILABILITY",
}

export enum ShopVPSHistoricalOrderStatus {
  INVOICE = "INVOICE",
  RECEIPT = "RECEIPT",
  UNKNOWN = "UNKNOWN",
  VOUCHER = "VOUCHER",
}

export enum ShopVPSShippingMethod {
  DANGEROUS_BATTERY = "DANGEROUS_BATTERY",
  ECONOMY = "ECONOMY",
  EXPRESS = "EXPRESS",
  PRIORITY = "PRIORITY",
  PRIORITY_FREE = "PRIORITY_FREE",
  SATHURDAY = "SATHURDAY",
  VELO = "VELO",
}

export enum ShopVpsAssortmentRange {
  extended = "extended",
  normal = "normal",
}

export enum ShopVpsAssortmentType {
  BRAND = "BRAND",
  BRAND_PARENT = "BRAND_PARENT",
  CHILD = "CHILD",
  DISCOUNTED = "DISCOUNTED",
  NOVELTIES = "NOVELTIES",
  PARENT = "PARENT",
}

export enum VelosizingVersion {
  CURRENT = "CURRENT",
  LEGACY = "LEGACY",
}

export interface FileReferenceInput {
  readonly fileId: string;
  readonly bucket: string;
}

export interface FileReferenceWhereInput {
  readonly bucket?: string | null;
  readonly bucket_not?: string | null;
  readonly bucket_in?: ReadonlyArray<string> | null;
  readonly bucket_not_in?: ReadonlyArray<string> | null;
  readonly bucket_lt?: string | null;
  readonly bucket_lte?: string | null;
  readonly bucket_gt?: string | null;
  readonly bucket_gte?: string | null;
  readonly bucket_contains?: string | null;
  readonly bucket_not_contains?: string | null;
  readonly bucket_starts_with?: string | null;
  readonly bucket_not_starts_with?: string | null;
  readonly bucket_ends_with?: string | null;
  readonly bucket_not_ends_with?: string | null;
  readonly fileId?: string | null;
  readonly fileId_not?: string | null;
  readonly fileId_in?: ReadonlyArray<string> | null;
  readonly fileId_not_in?: ReadonlyArray<string> | null;
  readonly fileId_lt?: string | null;
  readonly fileId_lte?: string | null;
  readonly fileId_gt?: string | null;
  readonly fileId_gte?: string | null;
  readonly fileId_contains?: string | null;
  readonly fileId_not_contains?: string | null;
  readonly fileId_starts_with?: string | null;
  readonly fileId_not_starts_with?: string | null;
  readonly fileId_ends_with?: string | null;
  readonly fileId_not_ends_with?: string | null;
  readonly AND?: ReadonlyArray<FileReferenceWhereInput> | null;
  readonly OR?: ReadonlyArray<FileReferenceWhereInput> | null;
  readonly NOT?: ReadonlyArray<FileReferenceWhereInput> | null;
}

export interface PageMetaWhereInput {
  readonly description?: string | null;
  readonly description_not?: string | null;
  readonly description_in?: ReadonlyArray<string> | null;
  readonly description_not_in?: ReadonlyArray<string> | null;
  readonly description_lt?: string | null;
  readonly description_lte?: string | null;
  readonly description_gt?: string | null;
  readonly description_gte?: string | null;
  readonly description_contains?: string | null;
  readonly description_not_contains?: string | null;
  readonly description_starts_with?: string | null;
  readonly description_not_starts_with?: string | null;
  readonly description_ends_with?: string | null;
  readonly description_not_ends_with?: string | null;
  readonly keywords?: string | null;
  readonly keywords_not?: string | null;
  readonly keywords_in?: ReadonlyArray<string> | null;
  readonly keywords_not_in?: ReadonlyArray<string> | null;
  readonly keywords_lt?: string | null;
  readonly keywords_lte?: string | null;
  readonly keywords_gt?: string | null;
  readonly keywords_gte?: string | null;
  readonly keywords_contains?: string | null;
  readonly keywords_not_contains?: string | null;
  readonly keywords_starts_with?: string | null;
  readonly keywords_not_starts_with?: string | null;
  readonly keywords_ends_with?: string | null;
  readonly keywords_not_ends_with?: string | null;
  readonly AND?: ReadonlyArray<PageMetaWhereInput> | null;
  readonly OR?: ReadonlyArray<PageMetaWhereInput> | null;
  readonly NOT?: ReadonlyArray<PageMetaWhereInput> | null;
}

export interface RespondToProductReviewInput {
  readonly productReviewId: string;
  readonly text: string;
}

export interface ShopAddressInput {
  readonly firstName?: string | null;
  readonly lastName?: string | null;
  readonly company?: string | null;
  readonly addressLine?: string | null;
  readonly addressLine2?: string | null;
  readonly postalCode?: string | null;
  readonly regionCode?: string | null;
  readonly city?: string | null;
  readonly countryCode?: string | null;
  readonly salutation?: string | null;
}

export interface ShopContactInput {
  readonly emailAddress?: string | null;
  readonly telNumber?: string | null;
}

export interface ShopFilterQueryInput {
  readonly key: string;
  readonly value?: string | null;
}

export interface ShopMoneyInput {
  readonly currency: string;
  readonly amount: number;
}

export interface ShopProductReviewInput {
  readonly rating?: number | null;
  readonly title?: string | null;
  readonly review?: string | null;
}

export interface ShopUserProfileInput {
  readonly displayName?: string | null;
  readonly birthday?: any | null;
  readonly phoneMobile?: string | null;
  readonly gender?: string | null;
  readonly address?: ShopAddressInput | null;
  readonly phonePrivate?: string | null;
  readonly phoneOffice?: string | null;
  readonly title?: string | null;
}

export interface ShopVPSSubmitOrderReturnPositionInput {
  readonly sku: string;
  readonly quantity: number;
  readonly price?: ShopMoneyInput | null;
  readonly discount?: ShopMoneyInput | null;
  readonly paymentType: string;
  readonly positionIndex: string;
  readonly reason: string;
}

export interface ShopVpsUserPreferenceInput {
  readonly assortmentRange?: ShopVpsAssortmentRange | null;
}

export interface TipTagWhereInput {
  readonly id?: string | null;
  readonly id_not?: string | null;
  readonly id_in?: ReadonlyArray<string> | null;
  readonly id_not_in?: ReadonlyArray<string> | null;
  readonly id_lt?: string | null;
  readonly id_lte?: string | null;
  readonly id_gt?: string | null;
  readonly id_gte?: string | null;
  readonly id_contains?: string | null;
  readonly id_not_contains?: string | null;
  readonly id_starts_with?: string | null;
  readonly id_not_starts_with?: string | null;
  readonly id_ends_with?: string | null;
  readonly id_not_ends_with?: string | null;
  readonly label?: string | null;
  readonly label_not?: string | null;
  readonly label_in?: ReadonlyArray<string> | null;
  readonly label_not_in?: ReadonlyArray<string> | null;
  readonly label_lt?: string | null;
  readonly label_lte?: string | null;
  readonly label_gt?: string | null;
  readonly label_gte?: string | null;
  readonly label_contains?: string | null;
  readonly label_not_contains?: string | null;
  readonly label_starts_with?: string | null;
  readonly label_not_starts_with?: string | null;
  readonly label_ends_with?: string | null;
  readonly label_not_ends_with?: string | null;
  readonly AND?: ReadonlyArray<TipTagWhereInput> | null;
  readonly OR?: ReadonlyArray<TipTagWhereInput> | null;
  readonly NOT?: ReadonlyArray<TipTagWhereInput> | null;
}

export interface TipWhereInput {
  readonly id?: string | null;
  readonly id_not?: string | null;
  readonly id_in?: ReadonlyArray<string> | null;
  readonly id_not_in?: ReadonlyArray<string> | null;
  readonly id_lt?: string | null;
  readonly id_lte?: string | null;
  readonly id_gt?: string | null;
  readonly id_gte?: string | null;
  readonly id_contains?: string | null;
  readonly id_not_contains?: string | null;
  readonly id_starts_with?: string | null;
  readonly id_not_starts_with?: string | null;
  readonly id_ends_with?: string | null;
  readonly id_not_ends_with?: string | null;
  readonly number?: number | null;
  readonly number_not?: number | null;
  readonly number_in?: ReadonlyArray<number> | null;
  readonly number_not_in?: ReadonlyArray<number> | null;
  readonly number_lt?: number | null;
  readonly number_lte?: number | null;
  readonly number_gt?: number | null;
  readonly number_gte?: number | null;
  readonly tags_every?: TipTagWhereInput | null;
  readonly tags_some?: TipTagWhereInput | null;
  readonly tags_none?: TipTagWhereInput | null;
  readonly title?: string | null;
  readonly title_not?: string | null;
  readonly title_in?: ReadonlyArray<string> | null;
  readonly title_not_in?: ReadonlyArray<string> | null;
  readonly title_lt?: string | null;
  readonly title_lte?: string | null;
  readonly title_gt?: string | null;
  readonly title_gte?: string | null;
  readonly title_contains?: string | null;
  readonly title_not_contains?: string | null;
  readonly title_starts_with?: string | null;
  readonly title_not_starts_with?: string | null;
  readonly title_ends_with?: string | null;
  readonly title_not_ends_with?: string | null;
  readonly introText?: string | null;
  readonly introText_not?: string | null;
  readonly introText_in?: ReadonlyArray<string> | null;
  readonly introText_not_in?: ReadonlyArray<string> | null;
  readonly introText_lt?: string | null;
  readonly introText_lte?: string | null;
  readonly introText_gt?: string | null;
  readonly introText_gte?: string | null;
  readonly introText_contains?: string | null;
  readonly introText_not_contains?: string | null;
  readonly introText_starts_with?: string | null;
  readonly introText_not_starts_with?: string | null;
  readonly introText_ends_with?: string | null;
  readonly introText_not_ends_with?: string | null;
  readonly introImage?: FileReferenceWhereInput | null;
  readonly showInRepository?: boolean | null;
  readonly showInRepository_not?: boolean | null;
  readonly slug?: string | null;
  readonly slug_not?: string | null;
  readonly slug_in?: ReadonlyArray<string> | null;
  readonly slug_not_in?: ReadonlyArray<string> | null;
  readonly slug_lt?: string | null;
  readonly slug_lte?: string | null;
  readonly slug_gt?: string | null;
  readonly slug_gte?: string | null;
  readonly slug_contains?: string | null;
  readonly slug_not_contains?: string | null;
  readonly slug_starts_with?: string | null;
  readonly slug_not_starts_with?: string | null;
  readonly slug_ends_with?: string | null;
  readonly slug_not_ends_with?: string | null;
  readonly htmlTitle?: string | null;
  readonly htmlTitle_not?: string | null;
  readonly htmlTitle_in?: ReadonlyArray<string> | null;
  readonly htmlTitle_not_in?: ReadonlyArray<string> | null;
  readonly htmlTitle_lt?: string | null;
  readonly htmlTitle_lte?: string | null;
  readonly htmlTitle_gt?: string | null;
  readonly htmlTitle_gte?: string | null;
  readonly htmlTitle_contains?: string | null;
  readonly htmlTitle_not_contains?: string | null;
  readonly htmlTitle_starts_with?: string | null;
  readonly htmlTitle_not_starts_with?: string | null;
  readonly htmlTitle_ends_with?: string | null;
  readonly htmlTitle_not_ends_with?: string | null;
  readonly meta?: PageMetaWhereInput | null;
  readonly AND?: ReadonlyArray<TipWhereInput> | null;
  readonly OR?: ReadonlyArray<TipWhereInput> | null;
  readonly NOT?: ReadonlyArray<TipWhereInput> | null;
}

export interface TravelReportTagWhereInput {
  readonly id?: string | null;
  readonly id_not?: string | null;
  readonly id_in?: ReadonlyArray<string> | null;
  readonly id_not_in?: ReadonlyArray<string> | null;
  readonly id_lt?: string | null;
  readonly id_lte?: string | null;
  readonly id_gt?: string | null;
  readonly id_gte?: string | null;
  readonly id_contains?: string | null;
  readonly id_not_contains?: string | null;
  readonly id_starts_with?: string | null;
  readonly id_not_starts_with?: string | null;
  readonly id_ends_with?: string | null;
  readonly id_not_ends_with?: string | null;
  readonly label?: string | null;
  readonly label_not?: string | null;
  readonly label_in?: ReadonlyArray<string> | null;
  readonly label_not_in?: ReadonlyArray<string> | null;
  readonly label_lt?: string | null;
  readonly label_lte?: string | null;
  readonly label_gt?: string | null;
  readonly label_gte?: string | null;
  readonly label_contains?: string | null;
  readonly label_not_contains?: string | null;
  readonly label_starts_with?: string | null;
  readonly label_not_starts_with?: string | null;
  readonly label_ends_with?: string | null;
  readonly label_not_ends_with?: string | null;
  readonly AND?: ReadonlyArray<TravelReportTagWhereInput> | null;
  readonly OR?: ReadonlyArray<TravelReportTagWhereInput> | null;
  readonly NOT?: ReadonlyArray<TravelReportTagWhereInput> | null;
}

export interface TravelReportWhereInput {
  readonly id?: string | null;
  readonly id_not?: string | null;
  readonly id_in?: ReadonlyArray<string> | null;
  readonly id_not_in?: ReadonlyArray<string> | null;
  readonly id_lt?: string | null;
  readonly id_lte?: string | null;
  readonly id_gt?: string | null;
  readonly id_gte?: string | null;
  readonly id_contains?: string | null;
  readonly id_not_contains?: string | null;
  readonly id_starts_with?: string | null;
  readonly id_not_starts_with?: string | null;
  readonly id_ends_with?: string | null;
  readonly id_not_ends_with?: string | null;
  readonly published?: boolean | null;
  readonly published_not?: boolean | null;
  readonly slug?: string | null;
  readonly slug_not?: string | null;
  readonly slug_in?: ReadonlyArray<string> | null;
  readonly slug_not_in?: ReadonlyArray<string> | null;
  readonly slug_lt?: string | null;
  readonly slug_lte?: string | null;
  readonly slug_gt?: string | null;
  readonly slug_gte?: string | null;
  readonly slug_contains?: string | null;
  readonly slug_not_contains?: string | null;
  readonly slug_starts_with?: string | null;
  readonly slug_not_starts_with?: string | null;
  readonly slug_ends_with?: string | null;
  readonly slug_not_ends_with?: string | null;
  readonly tags_every?: TravelReportTagWhereInput | null;
  readonly tags_some?: TravelReportTagWhereInput | null;
  readonly tags_none?: TravelReportTagWhereInput | null;
  readonly title?: string | null;
  readonly title_not?: string | null;
  readonly title_in?: ReadonlyArray<string> | null;
  readonly title_not_in?: ReadonlyArray<string> | null;
  readonly title_lt?: string | null;
  readonly title_lte?: string | null;
  readonly title_gt?: string | null;
  readonly title_gte?: string | null;
  readonly title_contains?: string | null;
  readonly title_not_contains?: string | null;
  readonly title_starts_with?: string | null;
  readonly title_not_starts_with?: string | null;
  readonly title_ends_with?: string | null;
  readonly title_not_ends_with?: string | null;
  readonly date?: any | null;
  readonly date_not?: any | null;
  readonly date_in?: ReadonlyArray<any> | null;
  readonly date_not_in?: ReadonlyArray<any> | null;
  readonly date_lt?: any | null;
  readonly date_lte?: any | null;
  readonly date_gt?: any | null;
  readonly date_gte?: any | null;
  readonly introText?: string | null;
  readonly introText_not?: string | null;
  readonly introText_in?: ReadonlyArray<string> | null;
  readonly introText_not_in?: ReadonlyArray<string> | null;
  readonly introText_lt?: string | null;
  readonly introText_lte?: string | null;
  readonly introText_gt?: string | null;
  readonly introText_gte?: string | null;
  readonly introText_contains?: string | null;
  readonly introText_not_contains?: string | null;
  readonly introText_starts_with?: string | null;
  readonly introText_not_starts_with?: string | null;
  readonly introText_ends_with?: string | null;
  readonly introText_not_ends_with?: string | null;
  readonly introImage?: FileReferenceWhereInput | null;
  readonly htmlTitle?: string | null;
  readonly htmlTitle_not?: string | null;
  readonly htmlTitle_in?: ReadonlyArray<string> | null;
  readonly htmlTitle_not_in?: ReadonlyArray<string> | null;
  readonly htmlTitle_lt?: string | null;
  readonly htmlTitle_lte?: string | null;
  readonly htmlTitle_gt?: string | null;
  readonly htmlTitle_gte?: string | null;
  readonly htmlTitle_contains?: string | null;
  readonly htmlTitle_not_contains?: string | null;
  readonly htmlTitle_starts_with?: string | null;
  readonly htmlTitle_not_starts_with?: string | null;
  readonly htmlTitle_ends_with?: string | null;
  readonly htmlTitle_not_ends_with?: string | null;
  readonly meta?: PageMetaWhereInput | null;
  readonly AND?: ReadonlyArray<TravelReportWhereInput> | null;
  readonly OR?: ReadonlyArray<TravelReportWhereInput> | null;
  readonly NOT?: ReadonlyArray<TravelReportWhereInput> | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
