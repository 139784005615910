import { SHOP_PATH } from "../../../config/locations";
import { makeFullUrl } from "../../../utils/makeFullUrl";
import type { UseAssortment_assortment } from "../../categories/hooks/types/UseAssortment";
import type { BaseProductNavItemParts } from "../queries/types/BaseProductNavItemParts";
import type { VariantProductNavItemParts } from "../queries/types/VariantProductNavItemParts";
import type { ProductSlug } from "./makeFullProductSlug";
import makeFullProductSlug from "./makeFullProductSlug";

export type ProductNaviItemParts =
  | VariantProductNavItemParts
  | BaseProductNavItemParts;
export const makeCanonicalPath = (product: ProductNaviItemParts) =>
  `${SHOP_PATH}${
    // sometimes null when there is an error in the data, see https://git.panter.ch/manul/vps/veloplus-shop/-/issues/1109
    product?.vpsPrimaryAssortmentPath?.vpsLeafLink?.assortmentTexts?.slug ?? ""
  }`;

export const makeCanonicalUrlForAssortment = (
  assortment: UseAssortment_assortment,
) => {
  return assortment?.texts?.slug
    ? makeFullUrl(`${SHOP_PATH}${assortment?.texts?.slug}`)
    : null;
};
const makeCanonicalUrl = (
  baseProduct: ProductNaviItemParts,
  productOrVariant: ProductSlug,
) => {
  return makeFullUrl(
    `${makeCanonicalPath(baseProduct)}/${makeFullProductSlug(
      productOrVariant,
    )}`,
  );
};
export default makeCanonicalUrl;
