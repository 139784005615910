import { useEffect, useRef, useState } from "react";

const TOLLERANCE = 10;
const useIsLeftRight = () => {
  const ref = useRef<HTMLDivElement>();
  const innerContentRef = useRef<HTMLDivElement>();
  const [isLeft, setIsLeft] = useState(true);
  const [isRight, setIsRight] = useState(true);

  useEffect(() => {
    const element = ref.current;

    const calcIsLeftRight = () => {
      if (!element) {
        return;
      }

      const { scrollLeft, clientWidth, scrollWidth } = element;

      if (clientWidth < scrollWidth) {
        setIsLeft(scrollLeft < TOLLERANCE);
        setIsRight(scrollWidth - (scrollLeft + clientWidth) < TOLLERANCE);
      } else {
        // no scroll at all
        setIsLeft(true);
        setIsRight(true);
      }
    };

    if (element) {
      element.addEventListener("scroll", calcIsLeftRight);
      // do it once
      calcIsLeftRight();
    }
    let resizeObserver: ResizeObserver;
    if (window?.ResizeObserver) {
      // not defined in tests and maybe some browsers
      resizeObserver = new ResizeObserver(calcIsLeftRight);
      resizeObserver.observe(innerContentRef?.current);
    }

    return () => {
      resizeObserver?.disconnect();
      if (element) {
        element.removeEventListener("scroll", calcIsLeftRight);
      }
    };
  }, [ref, innerContentRef]);

  return {
    ref,
    innerContentRef,
    isLeft,
    isRight,
  };
};
export default useIsLeftRight;
