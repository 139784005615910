import gql from "graphql-tag";
import {
  SimpleProductPrices,
  ProductTextsParts,
  VariantProductNavItemParts,
  BaseProductTileParts,
} from "../../product/queries";

export const BookmarkParts = gql`
  fragment BookmarkParts on ShopBookmark {
    _id
    user {
      _id
    }
    product {
      _id
      texts {
        ...ProductTextsParts
      }

      ... on ShopSimpleProduct {
        vpsAvailability
        status
        ...VariantProductNavItemParts
        vpsLegacyArticleNumber
        ...SimpleProductPrices
      }
    }
  }
  ${ProductTextsParts}
  ${SimpleProductPrices}
  ${VariantProductNavItemParts}
`;

export const MyVisitedProductsFragment = gql`
  fragment MyVisitedProductsFragment on ShopUser {
    _id
    vpsVisitedProducts(limit: 15) {
      _id
      product {
        ...BaseProductTileParts
      }
    }
  }
  ${BaseProductTileParts}
`;
