import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import type { Me } from "./types/Me";

export const PREFERENCES_FRAGMENT = gql`
  fragment Preferences on ShopVpsUserPreferences {
    assortmentRange
  }
`;
export const GET_ME = gql`
  query Me {
    me {
      _id
      name
      roles
      allowedActions
      adminResources
      emails {
        address
        verified
      }
      vpsPreferences {
        ...Preferences
      }

      primaryEmail {
        address
        verified
      }
      isGuest
      username

      cart {
        _id
        items {
          _id
          quantity
        }
      }
    }
  }
  ${PREFERENCES_FRAGMENT}
`;

const useMe = () => {
  const { data, previousData, error, loading, refetch } = useQuery<Me>(GET_ME, {
    fetchPolicy: "cache-and-network",
    ssr: false,
  });

  const me = (data ?? previousData)?.me;
  const isAdmin = me?.roles?.includes("admin") ?? false;
  return {
    me,

    isAdmin: isAdmin,
    allowedActions: me?.allowedActions ?? [],
    /**
     * @deprecated, use `allowedActions` instead
     */
    permissions: me?.allowedActions ?? [],
    isLoggedIn: (me && !me?.isGuest) || false,
    hasUsername: !!me?.username,
    isVerified: !me?.isGuest && me?.primaryEmail?.verified,
    cartId: me?.cart?._id,
    cartCount: me?.cart
      ? me?.cart.items.reduce((total, item) => total + item.quantity, 0)
      : 0,
    error,
    refetch,
    loading: !me && loading,
  };
};

export default useMe;
