import React from "react";
import { createGlobalStyle } from "styled-components";
import { Reset } from "styled-reset";
import { selectColor, selectSize } from "../utils/themeUtils";

const Styles = createGlobalStyle`

body {
    font-family: 'Open Sans', sans-serif;
    background-color: ${selectColor("grey")};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;

}
* {
    box-sizing: border-box;
}


body, #__next {
  overflow-x: hidden;

}

.react-page-controls-mode-toggle-control-group {
  margin-right: 8px;
}

.react-datepicker__current-month--hasMonthDropdown {
  display: none;
}

.grecaptcha-badge {
  visibility: hidden;
}
:target::before {
    content: '';
    display: block;
    height:      ${selectSize("anchorLinkOffset")}px;
    margin-top: -${selectSize("anchorLinkOffset", (v) => -v)}px;
}
`;

export default React.memo(() => (
  <>
    <Reset />
    <Styles />
  </>
));
