import { SHOP_PATH } from "../../../config/locations";
import type { ProductNaviItemParts } from "./makeCanonicalUrl";
import { makeCanonicalPath } from "./makeCanonicalUrl";
import makeFullProductSlug from "./makeFullProductSlug";

export type MinProduct = {
  _id: string;
  texts: {
    slug: string;
  };
};

export type MinCanonicalNavItemProduct = MinProduct & ProductNaviItemParts;
const makeProductNavItem = (
  product: MinProduct,
  parentPath: string = SHOP_PATH,
  color?: string,
) => ({
  href: `${SHOP_PATH}/product?slug=${makeFullProductSlug(product)}${
    color ? `&color=${encodeURIComponent(color)}` : ""
  }`,
  path: `${parentPath}/${makeFullProductSlug(product)}${
    color ? `?color=${encodeURIComponent(color)}` : ""
  }`,
});

export const makeProductCanonicalNavItem = (
  product: MinCanonicalNavItemProduct,
) => makeProductNavItem(product, makeCanonicalPath(product));

export default makeProductNavItem;
