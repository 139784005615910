import type { Thunk } from "easy-peasy";
import { thunk } from "easy-peasy";
import gql from "graphql-tag";
import type { SubmitForm, SubmitFormVariables } from "./types/SubmitForm";
import type { Injections, AppModel } from "../../../lib/types";
import type { FormType } from "./../../../types/global-types";

/* 📌 ACTION-CREATORS */
interface FormState {}

const initialState = {
  /* 📌 INITIAL-STATE */
};

export interface FormModel extends FormState {
  submit: Thunk<
    FormModel,
    {
      type: FormType;
      captchaToken: string;
      data: object;
      file?: File | null;
    },
    Injections,
    AppModel
  >;
}

const model: FormModel = {
  ...initialState,
  /* 📌 ACTIONS */

  /* 📌 THUNKS */
  submit: thunk(
    async (
      actions,
      { type, captchaToken, data, file },
      { injections: { apollo } },
    ) => {
      const result = await apollo.mutate<SubmitForm, SubmitFormVariables>({
        mutation: gql`
          mutation SubmitForm(
            $type: FormType!
            $captchaToken: String!
            $data: JSON!
            $file: Upload
          ) {
            submitForm(
              input: {
                type: $type
                captchaToken: $captchaToken
                data: $data
                file: $file
              }
            ) {
              success
            }
          }
        `,
        variables: { type, captchaToken, data, file },
      });

      return null;
    },
  ),
};

export default model;
