import gql from "graphql-tag";
import { ShopAddressParts } from "../../shop/queries";

export const ProfileParts = gql`
  fragment ProfileParts on ShopUser {
    _id
    isGuest
    name
    username
    primaryEmail {
      address
      verified
    }
    emails {
      address
      verified
    }
    profile {
      gender
      birthday
      phoneOffice
      phonePrivate
      phoneMobile
      address {
        ...ShopAddressParts
      }
    }
  }
  ${ShopAddressParts}
`;
