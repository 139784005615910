import type { ApolloClient } from "@apollo/client";
import gql from "graphql-tag";
import type {
  ShopAddressInput,
  ShopContactInput,
  ShopUserProfileInput,
} from "../../../types/global-types";
import { ProfileOrderParts } from "../../order/queries";
import { ProfileParts } from "../queries";
import type {
  UpdateContactAndBillingAddress,
  UpdateContactAndBillingAddressVariables,
} from "./types/UpdateContactAndBillingAddress";

const updateContactAndBillingAddress = async (
  apollo: ApolloClient<object>,
  variables: {
    contact?: ShopContactInput;
    billingAddress?: ShopAddressInput;
    deliveryAddress?: ShopAddressInput | false;
    profile?: ShopUserProfileInput;
  },
) => {
  const { contact, billingAddress, deliveryAddress, profile } = variables;

  await apollo.mutate<
    UpdateContactAndBillingAddress,
    UpdateContactAndBillingAddressVariables
  >({
    mutation: gql`
      mutation UpdateContactAndBillingAddress(
        $contact: ShopContactInput
        $billingAddress: ShopAddressInput
        $meta: ShopJSON
        $profile: ShopUserProfileInput!
      ) {
        updateUserProfile(profile: $profile) {
          _id
          ...ProfileParts
          cart {
            ...ProfileOrderParts
          }
        }
        updateCart(
          contact: $contact
          billingAddress: $billingAddress
          meta: $meta
        ) {
          _id
          user {
            ...ProfileParts
            cart {
              ...ProfileOrderParts
            }
          }
        }
      }
      ${ProfileParts}
      ${ProfileOrderParts}
    `,
    variables: {
      contact,
      billingAddress,
      profile: profile ?? {},
      ...(deliveryAddress === undefined
        ? {}
        : deliveryAddress === false
        ? { meta: { deliveryAddress: null } }
        : { meta: { deliveryAddress } }),
    },
  });
};
export default updateContactAndBillingAddress;
